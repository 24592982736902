/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import Form from "antd/lib/form";
import axios from "axios";
import {authHeader, successMessage, errorMessage} from "../../../../../_helpers";
import Input from "antd/lib/input";
import Button from "antd/lib/button";

const apiUrl = process.env.REACT_APP_API;

const PaypalPayment = (props) => {
    let {fieldData, paymentOptionKey, centerId} = props
    const [form] = Form.useForm();

    const [state, setState] = useState({
        loader: false,
        success: false,
        errorMessage: false
    })

    useEffect(() => {
        if (fieldData) {
            let {
                email
            } = fieldData
            form.setFieldsValue({
                email
            })
        }
        return () => {
        }
    }, [fieldData, form]);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not validate email!'
        },
    };

    const onFinish = async v => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        let {
            email
        } = v

        const postData = {
            key: paymentOptionKey,
            email
        }
        const res = await axios.put(`${apiUrl}/dashboard/centers/payouts/${centerId}`, postData, {
            headers: authHeader()
        })

        const {data} = res

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false

                }
            })
            return successMessage('Payment method successfully updated');
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })
            return errorMessage(data.error.message ? data.error.message : 'Something went wrong')
        }
    }
    let {loader} = state

    return (
        <>
            {loader ? (
                <div className="loader-box transparent">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}
            <Form
                form={form}
                name="login_form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                scrollToFirstError={true}
            >
                <div className="form-box">

                    <div className="field-row">
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{type: 'email', required: true}]}
                        >
                            <Input
                                placeholder="Write your PayPal email"
                            />
                        </Form.Item>
                    </div>

                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="btn btn-big whit-border border-color-pink bg-pink color-white"
                            >
                                Update
                            </Button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </>
    );
}

export default PaypalPayment;
