import {setLocalStorage, clearLocalStorage} from "../_helpers";

export const signin = (userData) => {

    setLocalStorage("user", userData, true)
    return {
        type: 'SIGN_IN',
    }
}

export const signout = () => {

    clearLocalStorage()
    return {
        type: 'SIGN_OUT',
    }
}
