/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import {
    authHeader,
    errorMessage,
} from "../../../../_helpers";
import Table from 'antd/lib/table';
import Pagination from 'antd/lib/pagination';
import Spin from 'antd/lib/spin';
import Breadcrumb from "antd/lib/breadcrumb";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import Button from "antd/lib/button";
import {Link} from "react-router-dom";

const {Search} = Input;
const apiUrl = process.env.REACT_APP_API;

const ResultsList = () => {
    let unmounted = useRef(false);
    const [state, setState] = useState({
        list: {},
        listTotal: 0,
        currentPage: 1,
        pp: 10,
        loader: true,
        searchValue: '',
        gradeValue: '',
        centerValue: '',
        GradesList: [],
        CentersList: []
    })

    useEffect(() => {
        document.body.className = 'page-student view-list';
        getList().then(res => {
            let {code, payload, message} = res
            if (code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: payload.list,
                            listTotal: payload.count
                        }
                    })
                }
            } else {
                if (!unmounted.current) {
                    return errorMessage(message)
                }
            }
        });
        return () => {
            document.body.className = '';
            unmounted.current = true
        }
    }, []);

    useEffect(() => {
        getGradesList().then(res => {
            if (res) {
                setState(prevState => {
                    return {
                        ...prevState,
                        GradesList: res
                    }
                })
            }
        })

        getCenterList().then(res => {
            if (res) {
                setState(prevState => {
                    return {
                        ...prevState,
                        CentersList: res,
                    }
                })
            }
        })
        return () => {
        }
    }, []);

    const getGradesList = async () => {
        let res = await axios.get(`${apiUrl}/student/grades`);
        let {data} = res;
        if (data.error === null) {
            return data.payload
        }
    }

    const getCenterList = async () => {
        let queryParams = {}
        queryParams._start = 0
        queryParams._end = 250
        queryParams.search = ''

        let res = await axios.get(`${apiUrl}/student/centers`, {
            params: queryParams
        });
        let {data} = res;

        if (data.error === null) {
            return data.payload
        }
    }

    const getList = async (start = 0, end = 10, name = '', grade = '', center = '') => {
        const queryParams = {
            _start: start,
            _end: end,
            name,
            grade,
            center
        };

        let res = await axios.get(`${apiUrl}/dashboard/results/list/`, {
            params: queryParams,
            headers: authHeader()
        });

        let {data} = res;

        if (data.error === null) {
            let {count, rows} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            };
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: data.error.code,
                message: data.error.message
            };
        }
    }

    const onChange = page => {
        let {searchValue, pp, gradeValue, centerValue} = state;
        let start = (page - 1) * pp,
            end = pp * page;

        setState(prevState => {
            return {
                ...prevState,
                currentPage: page,
                loader: true,
            }
        })

        getList(start, end, searchValue, gradeValue, centerValue).then(res => {
            if (res.code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: res.payload.list,
                            listTotal: res.payload.count
                        }
                    })
                }
            }
        })
    }

    const onGradeChange = value => {
        let {pp, searchValue, centerValue} = state;

        setState(prevState => {
            return {
                ...prevState,
                gradeValue: value,
                loader: true,
            }
        })
        getList(0, pp, searchValue, value, centerValue).then(res => {
            if (res.code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        list: res.payload.list,
                        listTotal: res.payload.count,
                        currentPage: 1
                    }
                })
            }
        })
    }

    const onCenterChange = value => {
        let {pp, searchValue, gradeValue} = state;

        setState(prevState => {
            return {
                ...prevState,
                centerValue: value,
                loader: true,
            }
        })
        getList(0, pp, searchValue, gradeValue, value).then(res => {
            if (res.code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        list: res.payload.list,
                        listTotal: res.payload.count,
                        currentPage: 1
                    }
                })
            }
        })
    }

    const columns = [
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
        },
        {
            title: 'Grade',
            dataIndex: 'gradeName',
            key: 'gradeName',
        },
        {
            title: 'Center Name',
            dataIndex: 'centerName',
            key: 'centerName',
        },
        {
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
        },
        {
            title: 'Award',
            dataIndex: 'award',
            key: 'award',
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'Action',
            key: 'action',
            className: 'action-wrap',
            width: 100,
            render: (item) => (
                <div className="action-box custom-button">
                    <Link className="ant-btn ant-btn-gray" to={`/result/edit/${item.action}`}>
                        Edit
                    </Link>
                </div>
            ),
        },
    ];

    let listDataSource = []

    Object.keys(state.list).map((key) => {
        const {
            id,
            award,
            centerName,
            gradeName,
            rank,
            state: province,
            studentFName,
            studentLName,
        } = state.list[key]

        listDataSource[key] = {
            key: id,
            action:id,
            centerName,
            studentName: `${studentFName} ${studentLName}`,
            state: province,
            rank,
            gradeName,
            award
        }
        return listDataSource;
    })

    const onSearch = value => {
        let {pp, gradeValue, centerValue} = state;

        setState(prevState => {
            return {
                ...prevState,
                searchValue: value,
                loader: true,
            }
        })

        getList(0, pp, value, gradeValue, centerValue).then(res => {
            if (res.code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        list: res.payload.list,
                        listTotal: res.payload.count,
                        currentPage: 1
                    }
                })
            }
        })
    }

    let {loader, pp, currentPage, listTotal, GradesList, CentersList} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }
            <Breadcrumb>
                <Breadcrumb.Item>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10.629"
                         viewBox="0 0 15 10.629">
                        <g transform="translate(-2.842 -7.258)">
                            <path
                                d="M24.174,7.573a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0V8.7c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314V7.572Z"
                                transform="translate(-6.332)" fill="#11289c"/>
                            <path
                                d="M24.174,12.938a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0v1.13c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314Z"
                                transform="translate(-6.332 -2.711)" fill="#11289c"/>
                            <path
                                d="M7.268,7.573a.314.314,0,0,0-.314-.314l-.022,0H3.177l-.022,0a.314.314,0,0,0-.314.314v3.8a.314.314,0,0,0,.314.314h3.8a.314.314,0,0,0,.314-.314v-3.8Z"
                                transform="translate(0 -0.001)" fill="#11289c"/>
                            <path
                                d="M24.174,20.107a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0v1.13c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314V20.106Z"
                                transform="translate(-6.332 -6.333)" fill="#11289c"/>
                            <path
                                d="M24.174,25.471a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0V26.6c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314Z"
                                transform="translate(-6.332 -9.043)" fill="#11289c"/>
                            <path
                                d="M7.268,20.106a.314.314,0,0,0-.314-.314l-.022,0H3.177l-.022,0a.314.314,0,0,0-.314.314v3.8a.314.314,0,0,0,.314.314h3.8a.314.314,0,0,0,.314-.314Z"
                                transform="translate(0 -6.333)" fill="#11289c"/>
                        </g>
                    </svg>
                    <span>Results</span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className="tool-box">
                <div className="filter-box">
                    <div className="field-box">
                        <Search
                            autoComplete={new Date().valueOf()}
                            placeholder="Search by Student Name"
                            onSearch={onSearch}/>
                    </div>
                    <div className="field-box">
                        <Select
                            virtual={false}
                            autoComplete={new Date().valueOf()}
                            showSearch
                            optionFilterProp="children"
                            placeholder="Select grade"
                            allowClear
                            showArrow
                            onChange={onGradeChange}
                        >
                            {Object.keys(GradesList).map((key) => {
                                let listItem = GradesList[key]
                                return (
                                    <Select.Option key={key}
                                                   value={listItem.id}>{listItem.label}</Select.Option>
                                )
                            })}
                        </Select>
                    </div>

                    <div className="field-box">
                        <Select
                            virtual={false}
                            autoComplete={new Date().valueOf()}
                            showSearch
                            optionFilterProp="children"
                            placeholder="Type your zip code, state or math center name"
                            allowClear
                            showArrow
                            onChange={onCenterChange}
                        >
                            <Select.Option key={'SpacePandaOnline'}
                                           value="Space Panda Online">Space Panda Online</Select.Option>
                            {Object.keys(CentersList).map((key) => {
                                let {centerName, state, zipCode} = CentersList[key]
                                return (
                                    <Select.Option key={key}
                                                   value={centerName}>{centerName}, {state}, {zipCode}</Select.Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className="action-box">
                    <Button
                        type="primary"
                        className="button"
                        onClick={() => {
                            window.location.href = "/result/create"
                        }}
                    >
                        Create
                    </Button>
                </div>
            </div>

            <Table pagination={false} columns={columns} dataSource={listDataSource}/>

            <Pagination
                simple
                defaultPageSize={pp}
                className="ant-table-pagination ant-table-pagination-right"
                current={currentPage}
                onChange={onChange}
                total={listTotal}
            />
        </>
    );
}

export default ResultsList;
