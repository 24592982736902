/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import {
    authHeader,
    errorMessage
} from "../../../../../_helpers";
import Table from 'antd/lib/table';
import Spin from 'antd/lib/spin';
import Breadcrumb from "antd/lib/breadcrumb";
import Button from "antd/lib/button";
import fileDownload from 'js-file-download';
import Input from "antd/lib/input";
import Pagination from "antd/lib/pagination";

const apiUrl = process.env.REACT_APP_API;
const moment = require("moment");
const {Search} = Input;

function AnnouncedEmail() {
    let unmounted = useRef(false);
    const [state, setState] = useState({
        list: [],
        listTotal: 0,
        currentPage: 1,
        pp: 10,
        loader: true,
        searchValue: ''
    })

    useEffect(() => {
        document.body.className = 'page-settings page-settings-announced-email view-list';
        getList().then(res => {
            let {code, payload, message} = res
            if (code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: payload.list,
                            listTotal: payload.count
                        }
                    })
                }
            } else {
                if (!unmounted.current) {
                    return errorMessage(message)
                }
            }
        });
        return () => {
            document.body.className = '';
            unmounted.current = true
        }
    }, []);

    const getList = async (start = 0, end = 10, search = '') => {
        const queryParams = {};
        queryParams._start = start;
        queryParams._end = end;
        queryParams.search = search
        let res = await axios.get(`${apiUrl}/dashboard/announceEmails/listdash`, {
            params: queryParams,
            headers: authHeader()
        });

        let {data} = res;

        if (data.error === null) {
            let {count, rows} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            };
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: data.error.code,
                message: data.error.message
            };
        }
    }

    const generateCsv = async () => {
        const res = await axios.get(`${apiUrl}/dashboard/announceEmails/list`, {
            headers: authHeader(),
            responseType: 'blob'
        })

        const {data} = res
        if (data) {
            fileDownload(data, 'AnnounceEmailsList.xlsx')
        }
    }


    const onChange = page => {
        let {searchValue, pp} = state;
        let start = (page - 1) * pp,
            end = pp * page;

        setState(prevState => {
            return {
                ...prevState,
                currentPage: page,
                loader: true,
            }
        })

        getList(start, end, searchValue).then(res => {
            if (res.code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: res.payload.list,
                            listTotal: res.payload.count
                        }
                    })
                }
            }
        })
    }

    const onSearch = value => {
        let {statusValue, pickerValue, pp} = state;

        setState(prevState => {
            return {
                ...prevState,
                searchValue: value,
                loader: true,
            }
        })
        getList(0, pp, value, statusValue, pickerValue).then(res => {
            if (res.code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: res.payload.list,
                            listTotal: res.payload.count,
                            currentPage: 1
                        }
                    })
                }
            }
        })
    }

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Center name',
            dataIndex: 'centerName',
            key: 'centerName',
        },
        {
            title: 'Created Date',
            dataIndex: 'createddate',
            key: 'createddate',
        }
    ];

    let listDataSource = []

    Object.keys(state.list).map((key) => {
        let {centerName, createdAt, email} = state.list[key]

        listDataSource[key] = {
            key,
            centerName,
            createddate: moment(createdAt, "DD/MM/YYYY").format("ddd, MMM D"),
            email,
        }
        return listDataSource;
    })

    let {loader, pp, currentPage, listTotal} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }

            <Breadcrumb>
                <Breadcrumb.Item>
                    <svg className="icon-settings" xmlns="http://www.w3.org/2000/svg" width="15"
                         height="15.002"
                         viewBox="0 0 15 15.002">
                        <path
                            d="M17.784,9.361l-1.677-.5A5.823,5.823,0,0,0,15.632,7.7l.821-1.535A.3.3,0,0,0,16.4,5.8L15.2,4.6a.305.305,0,0,0-.36-.055l-1.526.815a5.818,5.818,0,0,0-1.181-.5l-.5-1.655a.305.305,0,0,0-.3-.205H9.647a.305.305,0,0,0-.29.215l-.5,1.65a5.823,5.823,0,0,0-1.191.5l-1.5-.81a.305.305,0,0,0-.36.055L4.587,5.8a.3.3,0,0,0-.055.36l.811,1.5a5.813,5.813,0,0,0-.5,1.185l-1.657.5a.305.305,0,0,0-.215.29v1.69a.305.305,0,0,0,.215.29l1.667.5a5.808,5.808,0,0,0,.5,1.165l-.821,1.57a.3.3,0,0,0,.055.36l1.2,1.2a.305.305,0,0,0,.36.055l1.547-.825a5.834,5.834,0,0,0,1.151.47l.5,1.685a.305.305,0,0,0,.29.215h1.692a.305.305,0,0,0,.29-.215l.5-1.69a5.823,5.823,0,0,0,1.141-.47l1.557.83a.305.305,0,0,0,.36-.055l1.2-1.2a.3.3,0,0,0,.055-.36l-.831-1.55a5.813,5.813,0,0,0,.475-1.145l1.687-.5a.305.305,0,0,0,.215-.29V9.656a.305.305,0,0,0-.185-.3Zm-7.292,3.89a2.75,2.75,0,1,1,2.753-2.75,2.751,2.751,0,0,1-2.753,2.75Z"
                            transform="translate(-2.97 -3)"/>
                    </svg>
                    <span>Announced Email Addresses</span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className="tool-box">
                <div className="filter-box">
                    <div className="field-box">
                        <Search
                            autoComplete={new Date().valueOf()}
                            placeholder="Search by Center Name"
                            onSearch={onSearch}/>
                    </div>
                </div>
                <div className="action-box">
                    <Button
                        type="primary"
                        className="button"
                        onClick={generateCsv}
                    >
                        Generate XLSX
                    </Button>
                </div>
            </div>
            <Table pagination={false} columns={columns} dataSource={listDataSource}/>

            <Pagination
                simple
                defaultPageSize={pp}
                className="ant-table-pagination ant-table-pagination-right"
                current={currentPage}
                onChange={onChange}
                total={listTotal}
            />
        </>
    );
}

export default AnnouncedEmail;
