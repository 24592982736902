import message from "antd/lib/message";

message.config({
    maxCount: 1,
})

export function successMessage(text, duration = 2) {
    return new Promise((resolve, reject) => {
        message.success(text, duration).then(afterClose => {
            afterClose ? resolve(afterClose) : reject(Error("Success Message Rejected"));
        });
    });
}

export function errorMessage(text, duration = 2) {
    return new Promise((resolve, reject) => {
        message.error(text, duration).then(afterClose => {
            afterClose ? resolve(afterClose) : reject(Error("Error Message Rejected"));
        });
    });
}

