/* eslint-disable no-template-curly-in-string */
import React, {useState, useEffect} from 'react';
import Spin from 'antd/lib/spin';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Breadcrumb from 'antd/lib/breadcrumb';
import Empty from 'antd/lib/empty';
import Switch from 'antd/lib/switch';
import axios from "axios";
import {
    authHeader,
    errorMessage,
    removeLocalStorageItem,
    successMessage
} from "../../../../../_helpers";

const apiUrl = process.env.REACT_APP_API;

function GeneralSettingsUpdate(props) {
    let {settingId, gradeId} = props.match.params;
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loader: true,
        empty: true,
        fieldLabel: ''
    })

    useEffect(() => {
        document.body.className = 'page-settings page-settings-general view-edit';
        getDataById(settingId, gradeId).then(res => {
            if (res.code === 200) {
                let data = res.payload
                let {value, label, isActive} = data
                form.setFieldsValue({
                    value,
                    status: isActive,
                });
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                        empty: false,
                        fieldLabel: label
                    }
                })
            } else {
                setState(prevState => {
                    return {
                        ...prevState,
                        empty: true,
                        loader: false
                    }
                })
                errorMessage(res.message).then(res => {
                    if (res) {
                        window.location.href = "/settings/general/list";
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }
        });

        window.scroll(0, 0);
        return () => {
            document.body.className = '';
            removeLocalStorageItem('tempData');
        }
    }, [
        settingId,
        gradeId,
        form
    ]);
    const getDataById = async (key, grade) => {
        const postData = {
            key,
            gradeId: grade
        }

        let res = await axios.get(`${apiUrl}/dashboard/settings/get`, {
            params: postData,
            headers: authHeader()
        });
        let {data} = res;

        let payload
        if (data.error === null) {
            payload = {
                code: 200,
                payload: data.payload
            }
        } else {
            payload = {
                code: 400,
                message: data.error.message
            }
        }
        return payload;
    }
    const validateMessages = {
        required: '${label} is required!',
    };
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const Update = async v => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        const paramsData = {
            key: settingId,
            gradeId
        }
        let {value, status} = v
        let postData = {
            isActive: status,
            value
        };
        let res = await axios.put(`${apiUrl}/dashboard/settings/update`, postData, {
            params: paramsData,
            headers: authHeader()
        });
        let {data} = res;

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })
            await successMessage('Settings successfully Updated');
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })
            return errorMessage(data.error.message)
        }
    }
    let {empty, loader, fieldLabel} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : null
            }
            {
                empty ? (
                    <>
                        <Empty/>
                    </>
                ) : (
                    <>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/settings/captain-fee">
                                <svg className="icon-settings" xmlns="http://www.w3.org/2000/svg" width="15"
                                     height="15.002"
                                     viewBox="0 0 15 15.002">
                                    <path
                                        d="M17.784,9.361l-1.677-.5A5.823,5.823,0,0,0,15.632,7.7l.821-1.535A.3.3,0,0,0,16.4,5.8L15.2,4.6a.305.305,0,0,0-.36-.055l-1.526.815a5.818,5.818,0,0,0-1.181-.5l-.5-1.655a.305.305,0,0,0-.3-.205H9.647a.305.305,0,0,0-.29.215l-.5,1.65a5.823,5.823,0,0,0-1.191.5l-1.5-.81a.305.305,0,0,0-.36.055L4.587,5.8a.3.3,0,0,0-.055.36l.811,1.5a5.813,5.813,0,0,0-.5,1.185l-1.657.5a.305.305,0,0,0-.215.29v1.69a.305.305,0,0,0,.215.29l1.667.5a5.808,5.808,0,0,0,.5,1.165l-.821,1.57a.3.3,0,0,0,.055.36l1.2,1.2a.305.305,0,0,0,.36.055l1.547-.825a5.834,5.834,0,0,0,1.151.47l.5,1.685a.305.305,0,0,0,.29.215h1.692a.305.305,0,0,0,.29-.215l.5-1.69a5.823,5.823,0,0,0,1.141-.47l1.557.83a.305.305,0,0,0,.36-.055l1.2-1.2a.3.3,0,0,0,.055-.36l-.831-1.55a5.813,5.813,0,0,0,.475-1.145l1.687-.5a.305.305,0,0,0,.215-.29V9.656a.305.305,0,0,0-.185-.3Zm-7.292,3.89a2.75,2.75,0,1,1,2.753-2.75,2.751,2.751,0,0,1-2.753,2.75Z"
                                        transform="translate(-2.97 -3)"/>
                                </svg>
                                <span>General Settings</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Edit</Breadcrumb.Item>
                        </Breadcrumb>

                        <Form
                            form={form}
                            name="captain-fee-update"
                            onFinish={Update}
                            onFinishFailed={onFinishFailed}
                            validateMessages={validateMessages}
                        >
                            <div className="form-box">

                                <div className="field-row">
                                    <Form.Item
                                        name="value"
                                        label={fieldLabel}
                                        rules={[{required: true}]}>
                                        <Input placeholder="Url"/>
                                    </Form.Item>
                                </div>


                                <div className="field-row">
                                    <Form.Item name="status" label="Status" valuePropName="checked">
                                        <Switch defaultChecked/>
                                    </Form.Item>
                                </div>

                                <Form.Item shouldUpdate={true}>
                                    {() => (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="button"
                                        >
                                            Update
                                        </Button>
                                    )}
                                </Form.Item>

                            </div>
                        </Form>
                    </>
                )
            }
        </>
    )
}

export default GeneralSettingsUpdate;
