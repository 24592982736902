/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import DatePicker from 'antd/lib/date-picker'
import Button from 'antd/lib/button'
import Form from "antd/lib/form";

const moment = require("moment");
const {Search} = Input;
const {RangePicker} = DatePicker;

export function ToolBox(props) {
    const {statusPlaceholder, createUrl, hide, reset} = props
    const [form] = Form.useForm();
    const [hideFieldsArr] = useState(hide);
    const [searchFieldPlaceholders] = useState(props.searchFieldPlaceholders !== undefined ? props.searchFieldPlaceholders : "");
    const [countryList, setCountryList] = useState([])
    const [cityList, setCityList] = useState([])
    const [statusList, setStatusList] = useState([])
    const [resetFlag, setResetFlag] = useState('');
    useEffect(() => {
        if (reset && reset !== resetFlag) {
            form.resetFields();
            setResetFlag(reset)
        }

        if (props.countryList !== countryList) {
            setCountryList(props.countryList)
        }

        if (props.cityList !== cityList) {
            setCityList(props.cityList)
        }

        if (props.statusList !== statusList) {
            setStatusList(props.statusList)
        }

    }, [
        form,
        reset,
        resetFlag,
        props.countryList,
        countryList,
        props.cityList,
        cityList,
        props.statusList,
        statusList
    ]);

    const onSearch = value => props.searchValue(value)
    const onCustomerEmailSearch = value => props.customerEmailSearchValue(value)
    const onCaptainEmailSearch = value => props.captainEmailSearchValue(value)

    const onRangePickerChange = value => {
        let payload = {}
        if (value !== null) {
            if (value.length > 1) {
                payload = {
                    start: moment(value[0]).startOf('month').format('YYYY-MM-DD'),
                    end: moment(value[1]).endOf('month').format('YYYY-MM-DD')
                }
            } else {
                payload = {
                    start: moment(value[0]).startOf('month').format('YYYY-MM-DD')
                }
            }
            props.pickerValue(payload)
        } else {
            props.pickerValue(payload)
        }
    }

    const onStatusChange = value => value !== undefined ? props.statusValue(value) : props.statusValue('');

    const onCountryChange = value => value !== undefined ? props.countryDropdown(value) : props.countryDropdown('');

    const onCityChange = value => value !== undefined ? props.cityDropdown(value) : props.cityDropdown('');

    const options = [{value: 1, label: 'Active'}, {value: 0, label: 'Passive'}]


    return (
        <>
            <div className="tool-box">
                <Form form={form} name="tool_box">
                    <div className="filter-box">
                        {hideFieldsArr !== undefined && hideFieldsArr.search !== undefined && hideFieldsArr.search ? null : (
                            <div className="field-box">
                                <Form.Item name="text">
                                    <Search
                                        autoComplete={new Date().valueOf()}
                                        placeholder={searchFieldPlaceholders !== '' ? searchFieldPlaceholders[0] : 'input search text'}
                                        onSearch={onSearch}/>
                                </Form.Item>
                            </div>
                        )}

                        {hideFieldsArr !== undefined && hideFieldsArr.customerEmail !== undefined && hideFieldsArr.customerEmail ? null : (
                            <div className="field-box">
                                <Form.Item name="customerEmail">
                                    <Search
                                        autoComplete={new Date().valueOf()}
                                        placeholder={"Customer Email"}
                                        onSearch={onCustomerEmailSearch}/>
                                </Form.Item>
                            </div>
                        )}

                        {hideFieldsArr !== undefined && hideFieldsArr.captainEmail !== undefined && hideFieldsArr.captainEmail ? null : (
                            <div className="field-box">
                                <Form.Item name="captainEmail">
                                    <Search
                                        autoComplete={new Date().valueOf()}
                                        placeholder={"Captain Email"}
                                        onSearch={onCaptainEmailSearch}/>
                                </Form.Item>
                            </div>
                        )}

                        {hideFieldsArr !== undefined && hideFieldsArr.country !== undefined && hideFieldsArr.country ? null : (
                            <div className="field-box">
                                <Form.Item name="country">
                                    <Select
                                        virtual={false}
                                        autoComplete={new Date().valueOf()}
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Select Country"
                                        allowClear
                                        showArrow
                                        onChange={onCountryChange}
                                    >
                                        {Object.keys(countryList).map((key) => {
                                            let listItem = countryList[key]
                                            return (
                                                <Select.Option key={key}
                                                               value={listItem.value}>{listItem.label}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        )}

                        {hideFieldsArr !== undefined && hideFieldsArr.city !== undefined && hideFieldsArr.city ? null : (
                            <div className="field-box">
                                <Form.Item name="city">
                                    <Select
                                        virtual={false}
                                        autoComplete={new Date().valueOf()}
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Select City"
                                        allowClear
                                        showArrow
                                        onChange={onCityChange}
                                    >
                                        {Object.keys(cityList).map((key) => {
                                            let listItem = cityList[key]
                                            return (
                                                <Select.Option key={key}
                                                               value={listItem.value}>{listItem.label}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        )}

                        {hideFieldsArr !== undefined && hideFieldsArr.status !== undefined && hideFieldsArr.status ? null : (
                            <div className="field-box">
                                <Form.Item name="status">
                                    <Select
                                        autoComplete={new Date().valueOf()}
                                        virtual={false}
                                        placeholder={statusPlaceholder && statusPlaceholder !== '' ? statusPlaceholder : "Status"}
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={onStatusChange}
                                    >
                                        {statusList !== undefined && statusList.length > 0 ?
                                            Object.keys(statusList).map((key) => {
                                                let listItem = statusList[key]
                                                return (
                                                    <Select.Option key={key}
                                                                   value={listItem.value}>{listItem.label}</Select.Option>
                                                )
                                            })
                                            :
                                            Object.keys(options).map((key) => {
                                                let listItem = options[key]
                                                return (
                                                    <Select.Option key={key}
                                                                   value={listItem.value}>{listItem.label}</Select.Option>
                                                )
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </div>
                        )}

                        {hideFieldsArr !== undefined && hideFieldsArr.picker !== undefined && hideFieldsArr.picker ? null : (
                            <div className="field-box">
                                <Form.Item name="daterange">
                                    <RangePicker autoComplete={new Date().valueOf()}
                                                 picker="month" onChange={onRangePickerChange}/>
                                </Form.Item>
                            </div>
                        )}

                    </div>
                </Form>

                {
                    createUrl && createUrl !== '' ? (
                        <div className="action-box">
                            <Button
                                type="primary"
                                className="button"
                                onClick={() => {
                                    window.location.href = createUrl
                                }}
                            >
                                Create
                            </Button>
                        </div>
                    ) : null
                }
            </div>
        </>
    );
}

