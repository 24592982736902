/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import {
    authHeader,
    errorMessage,
} from "../../../../../_helpers";
import Table from 'antd/lib/table';
import Pagination from 'antd/lib/pagination';
import Tag from 'antd/lib/tag';
import Spin from 'antd/lib/spin';
import {ToolBox} from "../../../../partials/tool-box"
import {Link} from "react-router-dom";

const apiUrl = process.env.REACT_APP_API;
const moment = require("moment");

function StudentsList(props) {
    const {centerId} = props
    let unmounted = useRef(false);
    const [state, setState] = useState({
        list: {},
        listTotal: 0,
        currentPage: 1,
        pp: 10,
        loader: true,
        searchValue: ''
    })

    useEffect(() => {
        getList(centerId).then(res => {
            let {code, payload, message} = res
            if (code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: payload.list,
                            listTotal: payload.count
                        }
                    })
                }
            } else {
                if (!unmounted.current) {
                    return errorMessage(message)
                }
            }
        });
        return () => {
            unmounted.current = true
        }
    }, [centerId]);

    const getList = async (id, start = 0, end = 10, search = '') => {
        const queryParams = {
            _start: start,
            _end: end,
            search,
            center: id
        };

        let res = await axios.get(`${apiUrl}/dashboard/students/list/`, {
            params: queryParams,
            headers: authHeader()
        });

        let {data} = res;

        if (data.error === null) {
            let {count, rows} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            };
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: data.error.code,
                message: data.error.message
            };
        }
    }

    const onChange = page => {
        let {searchValue, pp} = state;
        let start = (page - 1) * pp,
            end = pp * page;

        setState(prevState => {
            return {
                ...prevState,
                currentPage: page,
                loader: true,
            }
        })

        getList(centerId, start, end, searchValue).then(res => {
            if (res.code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: res.payload.list,
                            listTotal: res.payload.count
                        }
                    })
                }
            }
        })
    }

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Parent Name',
            dataIndex: 'parentFullName',
            key: 'parentFullName',
        },
        {
            title: 'Created Date',
            dataIndex: 'createddate',
            key: 'createddate',
        },
        {
            title: 'Purchased',
            key: 'studentPurchase',
            width: 102,
            render: (item) => {
                let color = item.studentPurchase !== null ? 'green' : 'orange'
                let tag = item.studentPurchase !== null ? 'Purchased' : 'Not Purchased'
                return (
                    <Tag color={color}>
                        {tag.toUpperCase()}
                    </Tag>
                )
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 102,
            render: (status) => {
                let color = status === true ? 'green' : 'red'
                let tag = status === true ? 'Active' : 'Passive'
                return (
                    <Tag color={color}>
                        {tag.toUpperCase()}
                    </Tag>
                )
            },
        },
        {
            title: 'Action',
            key: 'action',
            className: 'action-wrap',
            width: 100,
            render: (item) => (
                <div className="action-box custom-button">
                    <Link className="ant-btn ant-btn-gray" to={`/student/edit/${item.action}`}>
                        Edit
                    </Link>
                </div>
            ),
        },
    ];

    let listDataSource = []

    Object.keys(state.list).map((key) => {
        let {id, firstName, lastName, email, parentFullName, studentPurchase, createdAt, isActive} = state.list[key]

        listDataSource[key] = {
            key: id,
            firstName,
            lastName,
            email,
            parentFullName,
            studentPurchase,
            // createddate: moment(createdAt, "YYYY-MM-DD").format("ddd, MMM D"),
            createddate: moment(createdAt, "YYYY-MM-DDTh:mm").format("ddd, MMM D, H:mm"),
            action: id,
            status: isActive
        }
        return listDataSource;
    })

    const handleSearchValue = props => {
        let {statusValue, pickerValue, pp} = state;

        setState(prevState => {
            return {
                ...prevState,
                searchValue: props,
                loader: true,
            }
        })
        getList(0, pp, props, statusValue, pickerValue).then(res => {
            if (res.code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: res.payload.list,
                            listTotal: res.payload.count,
                            currentPage: 1
                        }
                    })
                }
            }
        })
    }


    let {loader, pp, currentPage, listTotal} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }

            <ToolBox
                hide={{
                    customerEmail: true,
                    captainEmail: true,
                    search: false,
                    status: true,
                    picker: true,
                    country: true,
                    city: true
                }}
                searchFieldPlaceholders={["Search by Name, Last Name, Email"]}
                searchValue={handleSearchValue}
            />

            <Table pagination={false} columns={columns} dataSource={listDataSource}/>

            <Pagination
                simple
                defaultPageSize={pp}
                className="ant-table-pagination ant-table-pagination-right"
                current={currentPage}
                onChange={onChange}
                total={listTotal}
            />
        </>
    );
}

export default StudentsList;
