/* Student */
import StudentsList from "./components/pages/student/list/index";
import UpdateStudent from "./components/pages/student/edit/index";

/*Center*/
import CenterList from "./components/pages/center/list/index";
import UpdateCenter from "./components/pages/center/edit/index";

/* Setting */
import GeneralSettingsList from "./components/pages/settings/general/list/index"
import GeneralSettingsUpdate from "./components/pages/settings/general/edit/index"
import ProductsSettingsList from "./components/pages/settings/products/list/index"
import ProductsSettingsUpdate from "./components/pages/settings/products/edit/index"
import CounterSettingsList from "./components/pages/settings/counters/list/index"
import CounterSettingsUpdate from "./components/pages/settings/counters/edit/index"
import AnnouncedEmail from "./components/pages/settings/announced-email/list"
import FeaturedCentersList from "./components/pages/settings/featured-centers/list"
import FeaturedCenterCreate from "./components/pages/settings/featured-centers/create"
import FeaturedCenterEdit from "./components/pages/settings/featured-centers/edit"
import AttendanceRequests from "./components/pages/settings/attendance-requests/list";

/* Results */
import ResultsList from "./components/pages/results/list"
import ResultCreate from "./components/pages/results/create";
import ResultEdit from "./components/pages/results/edit";

const routes = [
    /* Student */
    {
        path: "/student/list",
        exact: true,
        name: "Student List",
        component: StudentsList
    },
    {
        path: "/student/edit/:studentId",
        exact: true,
        name: "Student Edit",
        component: UpdateStudent
    },

    /*Center*/
    {
        path: "/center/list",
        exact: true,
        name: "Center List",
        component: CenterList
    },
    {
        path: "/center/edit/:centerId",
        exact: true,
        name: "Center Edit",
        component: UpdateCenter
    },

    /*Settings*/
    {
        path: "/settings/general/list",
        exact: true,
        name: "General Setting List",
        component: GeneralSettingsList
    },
    {
        path: "/settings/general/edit/:settingId/:gradeId",
        exact: true,
        name: "General Setting Update",
        component: GeneralSettingsUpdate
    },
    {
        path: "/settings/product/list",
        exact: true,
        name: "General Setting List",
        component: ProductsSettingsList
    },
    {
        path: "/settings/product/edit/:settingId",
        exact: true,
        name: "General Setting Update",
        component: ProductsSettingsUpdate
    },
    {
        path: "/settings/counter/list",
        exact: true,
        name: "General Setting List",
        component: CounterSettingsList
    },
    {
        path: "/settings/counter/edit/:settingId",
        exact: true,
        name: "General Setting Update",
        component: CounterSettingsUpdate
    },
    {
        path: "/settings/announced-emails",
        exact: true,
        name: "Announced Email Addresses",
        component: AnnouncedEmail
    },
    {
        path: "/settings/featured-centers/list",
        exact: true,
        name: "Featured Centers List",
        component: FeaturedCentersList
    },
    {
        path: "/settings/featured-centers/create",
        exact: true,
        name: "Featured Center Create",
        component: FeaturedCenterCreate
    },
    {
        path: "/settings/featured-centers/edit/:featuredCenterId",
        exact: true,
        name: "Featured Center Edit",
        component: FeaturedCenterEdit
    },
    {
        path: "/settings/attendance-requests/list",
        exact: true,
        name: "Attendance Requests",
        component: AttendanceRequests
    },
    // Results
    {
        path: "/result/list",
        exact: true,
        name: "Results List",
        component: ResultsList
    },
    {
        path: "/result/create",
        exact: true,
        name: "Results Create",
        component: ResultCreate
    },
    {
        path: "/result/edit/:resultId",
        exact: true,
        name: "Results Edit",
        component: ResultEdit
    }
];
// Announced Email Addresses
export default routes;
