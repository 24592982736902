/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import Tabs, {TabPane} from 'rc-tabs';

import axios from "axios";
import {authHeader} from "../../../../_helpers";

import CheckPayment from './check'
import ZellePayment from './zelle'
import PaypalPayment from './paypal'
import BankTransferPayment from './bankTransfer'

const apiUrl = process.env.REACT_APP_API;

const PaymentComponent = (props) => {
    const {centerId} = props
    const [state, setState] = useState({
        errorMessage: '',
        loader: false,
        payOptions: {},
        currentPayOption: '',
        component: {
            check: CheckPayment,
            zelle: ZellePayment,
            paypal: PaypalPayment,
            bank_transfer: BankTransferPayment
        }
    })

    useEffect(() => {
        getPayOptions(centerId).then(res => {
            let {code, payload} = res

            if (code === 200) {
                let currentPayOptionItem = payload.find(item => item.isCurrentpayOption === 1)
                let currentPayOption = currentPayOptionItem ? currentPayOptionItem.key : ''
                setState(prevState => {
                    return {
                        ...prevState,
                        payOptions: payload,
                        currentPayOption: currentPayOption
                    }
                })
            } else {

            }
        })
        return () => {
        }
    }, [centerId]);

    const getPayOptions = async (id) => {
        let res = await axios.get(`${apiUrl}/dashboard/centers/payouts/${id}`, {
            headers: authHeader()
        })

        let {data} = res

        if (data.error === null) {
            return {
                code: 200,
                payload: data.payload
            }
        } else {
            return {
                code: 404,
                message: data.error.message ? data.error.message : 'Something went wrong',
            }
        }
    }

    function tabsChangeCallback(key) {
        setState(prevState => {
            return {
                ...prevState,
                currentPayOption: key
            }
        })
    }

    function setTabTitle(text, imageUrl) {
        return (
            <>
                <img src={imageUrl} alt=""/>
                <span>{text}</span>
            </>
        )
    }

    let {loader, payOptions, currentPayOption, component} = state

    return (
        <>
            {loader ? (
                <div className="loader-box transparent">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}
            <div className="payment-box">
                <Tabs activeKey={currentPayOption} animated={false} onChange={tabsChangeCallback}>
                    {Object.keys(payOptions).map((payOptionsKey) => {
                        let {key, label, iconUrl, centerPayOptionData} = payOptions[payOptionsKey]
                        let Component = component[key]
                        return (
                            <TabPane tab={setTabTitle(label, iconUrl)} key={key}>
                                {React.createElement(Component, {
                                    fieldData: centerPayOptionData,
                                    paymentOptionKey: key,
                                    centerId
                                })}
                            </TabPane>
                        )
                    })}
                </Tabs>
            </div>
        </>
    );
}

export default PaymentComponent;
