/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import axios from "axios";
import {
    authHeader,
    errorMessage, successMessage,
} from "../../../../_helpers";
import Spin from 'antd/lib/spin';
import Breadcrumb from "antd/lib/breadcrumb";
import Form from "antd/lib/form";
import Button from "antd/lib/button";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import Student from "./student";
import InputNumber from "antd/lib/input-number";

const apiUrl = process.env.REACT_APP_API;

function ResultCreate() {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        studentId: null,
        loader: false,
        uploaderLoader: false,
        centerLogo: '',
        file: '',
        GradesList: []
    })

    const validateMessages = {
        required: '${label} is required!',
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        document.body.className = 'page-results view-create';

        return () => {
            document.body.className = '';
        }
    }, []);

    useEffect(() => {
        getGradesList().then(res => {
            if (res) {
                setState(prevState => {
                    return {
                        ...prevState,
                        GradesList: res
                    }
                })
            }
        })
        return () => {
        }
    }, []);

    const getGradesList = async () => {
        let res = await axios.get(`${apiUrl}/student/grades`);
        let {data} = res;
        if (data.error === null) {
            return data.payload
        }
    }

    const Create = async values => {
        let {studentId} = state

        if (!studentId) {
            return errorMessage("Please select a student")
        }

        let {gradeId, rank, award, state: province, rankForFront} = values


        let postData = {
            studentId,
            gradeId,
            rank,
            rankForFront,
            award,
            state: province
        }

        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })

        let res = await axios.post(`${apiUrl}/dashboard/results/create`, postData, {
            headers: authHeader()
        });
        let {data} = res;

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })
            successMessage('Results successfully created').then(res => {
                if (res) {
                    window.location.href = `/result/list`
                }
            })
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })
            return errorMessage(data.error.message)
        }
    }

    const handleStudentIdChange = props => {
        setState(prevState => {
            return {
                ...prevState,
                studentId: props
            }
        })
    }

    let {loader, GradesList, studentId} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }
            <Breadcrumb>
                <Breadcrumb.Item href="/result/list">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10.629"
                         viewBox="0 0 15 10.629">
                        <g transform="translate(-2.842 -7.258)">
                            <path
                                d="M24.174,7.573a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0V8.7c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314V7.572Z"
                                transform="translate(-6.332)" fill="#11289c"/>
                            <path
                                d="M24.174,12.938a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0v1.13c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314Z"
                                transform="translate(-6.332 -2.711)" fill="#11289c"/>
                            <path
                                d="M7.268,7.573a.314.314,0,0,0-.314-.314l-.022,0H3.177l-.022,0a.314.314,0,0,0-.314.314v3.8a.314.314,0,0,0,.314.314h3.8a.314.314,0,0,0,.314-.314v-3.8Z"
                                transform="translate(0 -0.001)" fill="#11289c"/>
                            <path
                                d="M24.174,20.107a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0v1.13c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314V20.106Z"
                                transform="translate(-6.332 -6.333)" fill="#11289c"/>
                            <path
                                d="M24.174,25.471a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0V26.6c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314Z"
                                transform="translate(-6.332 -9.043)" fill="#11289c"/>
                            <path
                                d="M7.268,20.106a.314.314,0,0,0-.314-.314l-.022,0H3.177l-.022,0a.314.314,0,0,0-.314.314v3.8a.314.314,0,0,0,.314.314h3.8a.314.314,0,0,0,.314-.314Z"
                                transform="translate(0 -6.333)" fill="#11289c"/>
                        </g>
                    </svg>
                    <span>Results</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
            </Breadcrumb>

            <Form
                form={form}
                name="featured-center-create"
                onFinish={Create}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
            >
                <div className="form-box">
                    <div className="field-row half">
                        <Form.Item
                            name="gradeId"
                            label="Grade"
                            rules={[{required: true}]}>
                            <Select
                                virtual={false}
                                autoComplete={new Date().valueOf()}
                                showSearch
                                optionFilterProp="children"
                                placeholder="Select grade"
                                allowClear
                                showArrow
                            >
                                {Object.keys(GradesList).map((key) => {
                                    let listItem = GradesList[key]
                                    return (
                                        <Select.Option key={key}
                                                       value={listItem.id}>{listItem.label}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="state"
                            label="State">
                            <Input placeholder="Write State"/>
                        </Form.Item>
                    </div>

                    <div className="field-row half">
                        <Student studentId={studentId} handleStudentIdChange={handleStudentIdChange}/>

                        <Form.Item
                            name="rank"
                            label="Rank"
                            rules={[{required: true}]}>
                            <InputNumber
                                placeholder="Write Rank"
                            />
                        </Form.Item>
                    </div>

                    <div className="field-row half">
                        <Form.Item
                            name="award"
                            label="Award">
                            <Input placeholder="Write Award"/>
                        </Form.Item>

                        <Form.Item
                            name="rankForFront"
                            label="Rank For Front"
                            rules={[{required: true}]}>
                            <Input placeholder="Write Rank For Front"/>
                        </Form.Item>
                    </div>

                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="button"
                            >
                                Create
                            </Button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </>
    );
}

export default ResultCreate;
