/* eslint-disable no-template-curly-in-string */
import React, {useState, useEffect, useRef} from 'react';
import Switch from 'antd/lib/switch';
import Spin from 'antd/lib/spin';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Select from 'antd/lib/select';
import Empty from 'antd/lib/empty';
import Breadcrumb from 'antd/lib/breadcrumb';
import Radio from 'antd/lib/radio'
import Tooltip from 'antd/lib/tooltip';
import PhoneInput from "react-phone-input-2";

import axios from "axios";

import StudentsPaymentsList from '../payments/list/index'
import {
    authHeader,
    errorMessage,
    successMessage
} from "../../../../_helpers";

const apiUrl = process.env.REACT_APP_API;

function UpdateStudent(props) {
    let unmounted = useRef(false);
    const {studentId} = props.match.params;
    const [form] = Form.useForm();

    const [state, setState] = useState({
        loader: true,
        empty: true,
        CountriesList: [],
        GradesList: [],
        CentersList: [],
        centerField: false,
    })

    useEffect(() => {
        document.body.className = 'page-student view-edit';
        getGradesList().then(res => {
            if (res) {
                let GradesList = res
                getCountriesList().then(res => {
                    if (res) {
                        setState(prevState => {
                            return {
                                ...prevState,
                                loader: false,
                                CountriesList: res,
                                GradesList
                            }
                        })
                    }
                })
                getCenterList().then()
            }
        })
        getDataById(studentId).then(res => {
            if (res.code === 200) {
                const data = res.payload

                const {
                    firstName,
                    lastName,
                    email,
                    address,
                    parentFullName,
                    isActive,
                    isVerified,
                    centerId,
                    countryId,
                    gradeId,
                    phone,
                    howYouHeard
                } = data
                form.setFieldsValue({
                    status: isActive,
                    verified: isVerified,
                    email,
                    address,
                    parentName: parentFullName,
                    firstName,
                    lastName,
                    phone,
                    countryId,
                    gradeId,
                    howYouHeard,
                    center: 2
                });

                if (centerId) {
                    form.setFieldsValue({
                        center: 1,
                        centerId
                    })
                    setState(prevState => {
                        return {
                            ...prevState,
                            centerField: true
                        }
                    })
                }
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                        empty: false,
                    }
                })
            } else {
                setState(prevState => {
                    return {
                        ...prevState,
                        empty: true,
                        loader: false
                    }
                })
                errorMessage(res.message).then(res => {
                    if (res) {
                        window.location.href = "/student/list";
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }
        });

        return () => {
            document.body.className = '';
            unmounted.current = true;
        }

    }, [
        studentId,
        form
    ]);

    const getDataById = async (id) => {
        let res = await axios.get(`${apiUrl}/dashboard/students/${id}`, {
            headers: authHeader()
        });
        let {data} = res;

        let payload
        if (data.error === null) {
            payload = {
                code: 200,
                payload: data.payload
            }

            if (data.payload === null) {
                payload = {
                    code: 400,
                    message: 'Something went wrong'
                }
            }
        } else {
            payload = {
                code: 400,
                message: data.error.message
            }
        }
        return payload;
    }

    const getCountriesList = async () => {
        let queryParams = {}
        queryParams._start = 0
        queryParams._end = 250
        queryParams.search = ''

        let res = await axios.get(`${apiUrl}/countries/list`, {
            params: queryParams
        });
        let {data} = res;

        if (data.error === null) {
            return data.payload
        }
    }

    const getGradesList = async () => {
        let res = await axios.get(`${apiUrl}/student/grades`);
        let {data} = res;
        if (data.error === null) {
            return data.payload
        }
    }

    const getCenterList = async () => {
        let queryParams = {}
        queryParams._start = 0
        queryParams._end = 250
        queryParams.search = ''

        let res = await axios.get(`${apiUrl}/student/centers`, {
            params: queryParams
        });
        let {data} = res;

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    CentersList: data.payload,
                }
            })
        }
    }

    const validateMessages = {
        required: '${label} is required!',
    };
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const Update = async v => {
        let {
            firstName,
            lastName,
            gradeId,
            countryId,
            address,
            parentName,
            phone,
            centerId,
            status,
            verified
        } = v

        setState(prevState => {
            return {
                ...prevState,
                loader: true,
            }
        })

        let postData = {
            firstName,
            lastName,
            gradeId,
            countryId,
            address,
            parentName,
            phone,
            isVerified: verified,
            isActive: status
        }

        if (centerId) {
            postData['centerId'] = centerId
        }

        let res = await axios.put(`${apiUrl}/dashboard/students/${studentId}`, postData, {
            headers: authHeader()
        });

        let {data} = res;

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })
            return successMessage('Student successfully Updated');
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })
            return errorMessage(data.error.message)
        }
    }
    const centerChange = async e => {
        let value = e.target.value
        if (value === 1) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: true
                }
            })

            getCenterList().then(() => {
                setState(prevState => {
                    return {
                        ...prevState,
                        centerField: true,
                        loader: false
                    }
                })
            })
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    CentersList: [],
                    centerField: false
                }
            })
        }
    }
    let {empty, loader, CountriesList, GradesList, centerField, CentersList} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : null
            }
            {
                empty ? (
                    <>
                        <Empty/>
                    </>
                ) : (
                    <>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/student/list">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12">
                                    <path
                                        d="M16,11.5a.5.5,0,0,1-1,0V4.922L9.484,7.688A3.284,3.284,0,0,1,8,8a3.284,3.284,0,0,1-1.484-.312L.609,4.734Q0,4.422,0,3.992t.609-.726L6.516.312A3.284,3.284,0,0,1,8,0,3.284,3.284,0,0,1,9.484.312l5.906,2.953a.95.95,0,0,1,.594.656A.091.091,0,0,1,16,3.985V11.5ZM9.984,8.688,13,7.172v2.2q0,.672-1.461,1.148A11.5,11.5,0,0,1,8,11a11.5,11.5,0,0,1-3.539-.476Q3,10.047,3,9.375v-2.2L6.016,8.688A4.692,4.692,0,0,0,8,9a4.692,4.692,0,0,0,1.984-.312Z"
                                        fill="#11289c"/>
                                </svg>
                                <span>Students</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Edit</Breadcrumb.Item>
                        </Breadcrumb>

                        <Form
                            form={form}
                            name="student_update"
                            onFinish={Update}
                            onFinishFailed={onFinishFailed}
                            validateMessages={validateMessages}
                        >
                            <div className="form-box">
                                <div className="field-row">
                                    <Form.Item name="center" label="Choose your Learning Center">
                                        <Radio.Group onChange={centerChange}>
                                            <Radio value={1}>
                                                Learning Center
                                            </Radio>
                                            <br/>
                                            <Radio value={2}>
                                                Space Panda Online
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                {centerField ? (
                                    <div className="field-row">
                                        <Form.Item
                                            name="centerId"
                                            label="Choose Your Learning Center"
                                            rules={[{required: true, message: 'Center is required!'}]}
                                        >
                                            <Select
                                                virtual={false}
                                                autoComplete={new Date().valueOf()}
                                                showSearch
                                                optionFilterProp="children"
                                                placeholder="Type your zip code, state or math center name"
                                                allowClear
                                                showArrow
                                                filterOption={(input, option) => {
                                                    const string = `${option.children[0].props.children}, ${option.children[1]}, ${option.children[2]}`
                                                    return string.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                }
                                            >
                                                {Object.keys(CentersList).map((key) => {
                                                    let {id, centerName, state, zipCode} = CentersList[key]
                                                    return (
                                                        <Select.Option key={key}
                                                                       value={id}><span>{centerName}</span>{`, ${state}, ${zipCode}`}
                                                        </Select.Option>
                                                    )
                                                })}
                                            </Select>

                                        </Form.Item>
                                    </div>
                                ) : null}
                                <div className="field-row half">
                                    <Form.Item
                                        name="firstName"
                                        label="First Name"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            placeholder="Write your name"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="lastName"
                                        label="Last Name"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            placeholder="Write your last name"
                                        />
                                    </Form.Item>
                                </div>
                                <div className="field-row half">
                                    <Form.Item
                                        name="gradeId"
                                        label="Grade"
                                        rules={[{required: true}]}
                                    >

                                        <Select
                                            virtual={false}
                                            autoComplete={new Date().valueOf()}
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Select grade"
                                            allowClear
                                            showArrow
                                        >
                                            {Object.keys(GradesList).map((key) => {
                                                let listItem = GradesList[key]
                                                return (
                                                    <Select.Option key={key}
                                                                   value={listItem.id}>{listItem.label}</Select.Option>
                                                )
                                            })}
                                        </Select>

                                    </Form.Item>
                                    <Tooltip placement="left" color={"#ea0101"}
                                             title={"You can't edit Email"}>
                                        <Form.Item
                                            name="email"
                                            label="Email"
                                            rules={[{required: true}]}>
                                            <Input disabled={true} placeholder="Email"/>
                                        </Form.Item>
                                    </Tooltip>
                                </div>
                                <div className="field-row half">

                                    <Form.Item
                                        name="countryId"
                                        label="Country"
                                        rules={[{required: true}]}
                                    >

                                        <Select
                                            virtual={false}
                                            autoComplete={new Date().valueOf()}
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Select Country"
                                            allowClear
                                            showArrow
                                        >
                                            {Object.keys(CountriesList).map((key) => {
                                                let listItem = CountriesList[key]
                                                return (
                                                    <Select.Option key={key}
                                                                   value={listItem.id}>{listItem.countryName}</Select.Option>
                                                )
                                            })}
                                        </Select>

                                    </Form.Item>

                                    <Form.Item
                                        label="Address"
                                        name="address"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            placeholder="Building Number, Street Name, City, Postal Code or Zip Code"
                                        />
                                    </Form.Item>

                                </div>

                                <div className="field-row half">

                                    <Form.Item
                                        label="Parent Full Name"
                                        name="parentName"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            placeholder="Write Parent full name"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={
                                            <>
                                                <span>Phone</span>
                                                <span>(Optional)</span>
                                            </>
                                        }
                                        name="phone"
                                    >
                                        <PhoneInput
                                            autoComplete={new Date().valueOf()}
                                            inputProps={{
                                                name: 'phone',
                                            }}
                                            inputClass="ant-input"
                                            country={'us'}
                                            type="tel"
                                            tabIndex="3"
                                        />
                                    </Form.Item>

                                </div>

                                <div className="field-row half">

                                    <Form.Item
                                        label={
                                            <>
                                                <span>How you heard about us</span>
                                                <span>(Optional)</span>
                                            </>
                                        }
                                        name="howYouHeard"
                                    >
                                        <Input.TextArea disabled={true}
                                                        placeholder="Write how you heard about Space Panda Math Olympiad"
                                        />
                                    </Form.Item>

                                </div>

                                <div className="field-row operation">
                                    <Form.Item name="status" label="Status" valuePropName="checked">
                                        <Switch defaultChecked/>
                                    </Form.Item>

                                    <Form.Item name="verified" label="Email Verified" valuePropName="checked">
                                        <Switch defaultChecked/>
                                    </Form.Item>

                                </div>
                                <Form.Item shouldUpdate={true}>
                                    {() => (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="btn btn-big whit-border border-color-pink bg-pink color-white"
                                        >
                                            Update
                                        </Button>
                                    )}
                                </Form.Item>
                            </div>
                        </Form>

                        <StudentsPaymentsList studentId={studentId}/>
                    </>
                )
            }
        </>
    );
}

export default UpdateStudent;
