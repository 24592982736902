/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import Form from "antd/lib/form";
import Typography from "antd/lib/typography"
import axios from "axios";
import {authHeader, errorMessage, successMessage} from "../../../../../_helpers";
import Input from "antd/lib/input";
import Button from "antd/lib/button";

const apiUrl = process.env.REACT_APP_API;
const {Text} = Typography

const BankTransferPayment = (props) => {
    let {fieldData, paymentOptionKey, centerId} = props
    const [form] = Form.useForm();

    const [state, setState] = useState({
        loader: false,
        success: false,
        errorMessage: false
    })

    useEffect(() => {
        if (fieldData) {
            let {
                fullName,
                bankRouting,
                bankAccount,
            } = fieldData
            form.setFieldsValue({
                fullName,
                bankRouting,
                bankAccount,
                reBankAccount: bankAccount
            })
        }
        return () => {
        }
    }, [fieldData, form]);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not validate email!'
        },
    };

    const onFinish = async v => {

        let {
            fullName,
            bankRouting,
            bankAccount,
            reBankAccount
        } = v
        if (bankAccount !== reBankAccount) {
            form.setFields([
                {
                    name: 'reBankAccount',
                    errors: ['Account Number and Confirm Account Number don`t match'],
                },
            ]);
            return false
        }
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        const postData = {
            key: paymentOptionKey,
            fullName,
            bankRouting,
            bankAccount,
        }
        const res = await axios.put(`${apiUrl}/dashboard/centers/payouts/${centerId}`, postData, {
            headers: authHeader()
        })

        const {data} = res

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false

                }
            })
            return successMessage('Payment method successfully updated');
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })
            return errorMessage(data.error.message ? data.error.message : 'Something went wrong')
        }
    }

    const handleFieldFocus = e => {
        let {id} = e.target
        id = id.replace('bank_transfer_form_', '')
        if (id === "reBankAccount") {
            id = "bankAccount"
        }

        let hint = document.getElementById(id)
        if (!hint.classList.contains('active')) {
            hint.classList.add('active')
        }
    }

    const handleFieldFocusOut = e => {
        let {id} = e.target
        id = id.replace('bank_transfer_form_', '')
        if (id === "reBankAccount") {
            id = "bankAccount"
        }
        let hint = document.getElementById(id)
        hint.classList.remove('active')
    }
    let {loader} = state

    return (
        <>
            {loader ? (
                <div className="loader-box transparent">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}
            <Form
                form={form}
                name="bank_transfer_form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                scrollToFirstError={true}
            >
                <div className="form-box">
                    <div className="field-row">
                        <Form.Item
                            name="fullName"
                            label="Account Holder Name"
                            rules={[{required: true}]}
                        >
                            <Input
                                placeholder="Write your full name"
                            />
                        </Form.Item>
                    </div>


                    <div className="separator">
                        <div id="bankRouting">
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="11.174" height="14.35"
                                     viewBox="0 0 11.174 14.35">
                                  <path
                                      d="M-1183.542,226.525a.979.979,0,0,1-.534-.995c.014-.954,0-1.908,0-2.861,0-.642.284-.923.926-.925q1.482,0,2.965,0c.611,0,.89.277.894.882,0,.965-.016,1.931.009,2.895a.946.946,0,0,1-.557,1Zm-6.907-3.628a5.065,5.065,0,0,0,.014.538.694.694,0,0,0,.694.689c.583.023,1.168.026,1.751,0a.71.71,0,0,0,.729-.766c.008-2.649,0-5.3,0-7.946,0-.011,0-.022,0-.034a.752.752,0,0,0-.811-.8c-.472-.016-.944-.01-1.416-.006-.724.006-.962.249-.964.981,0,1.257,0,2.514,0,3.771Q-1190.449,221.112-1190.448,222.9Zm10.344-5.939c.481-.01.8-.262.808-.681q.024-1.682,0-3.365a.693.693,0,0,0-.73-.724q-1.634-.022-3.268,0a.725.725,0,0,0-.769.766c-.015,1.077-.017,2.154,0,3.231a.74.74,0,0,0,.826.776c.505.005,1.011,0,1.516,0C-1181.182,216.96-1180.643,216.968-1180.1,216.957Z"
                                      transform="translate(1190.45 -212.175)" fill="rgb(25,25,112)"/>
                                </svg>
                            </span>
                            <span className="text" data-text="Routing Number">123456789</span>
                        </div>
                        <div id="bankAccount">
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="11.174" height="14.35"
                                     viewBox="0 0 11.174 14.35">
                                  <path
                                      d="M-1183.542,226.525a.979.979,0,0,1-.534-.995c.014-.954,0-1.908,0-2.861,0-.642.284-.923.926-.925q1.482,0,2.965,0c.611,0,.89.277.894.882,0,.965-.016,1.931.009,2.895a.946.946,0,0,1-.557,1Zm-6.907-3.628a5.065,5.065,0,0,0,.014.538.694.694,0,0,0,.694.689c.583.023,1.168.026,1.751,0a.71.71,0,0,0,.729-.766c.008-2.649,0-5.3,0-7.946,0-.011,0-.022,0-.034a.752.752,0,0,0-.811-.8c-.472-.016-.944-.01-1.416-.006-.724.006-.962.249-.964.981,0,1.257,0,2.514,0,3.771Q-1190.449,221.112-1190.448,222.9Zm10.344-5.939c.481-.01.8-.262.808-.681q.024-1.682,0-3.365a.693.693,0,0,0-.73-.724q-1.634-.022-3.268,0a.725.725,0,0,0-.769.766c-.015,1.077-.017,2.154,0,3.231a.74.74,0,0,0,.826.776c.505.005,1.011,0,1.516,0C-1181.182,216.96-1180.643,216.968-1180.1,216.957Z"
                                      transform="translate(1190.45 -212.175)" fill="rgb(25,25,112)"/>
                                </svg>
                            </span>
                            <span className="text" data-text="Account Number">000123456789</span>
                        </div>
                        <div>
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="11.178" height="11.155"
                                     viewBox="0 0 11.178 11.155">
                                  <path
                                      d="M-727.671,227.262c0-.762,0-1.525,0-2.287a.761.761,0,0,1,.866-.869q1.515-.008,3.031,0c.6,0,.87.265.886.89.015.583,0,1.166,0,1.749,0,.931,0,1.861,0,2.792,0,.672-.263.935-.942.94q-1.481.012-2.963,0c-.609,0-.875-.275-.879-.894C-727.676,228.809-727.671,228.035-727.671,227.262Zm-6.384,7.15a.759.759,0,0,0,.827.843.742.742,0,0,0,.762-.808c.01-2.623.006-5.247,0-7.87a1.2,1.2,0,0,0-.046-.332.673.673,0,0,0-.585-.528.771.771,0,0,0-.962.825c0,1.312,0,2.623,0,3.935S-734.061,233.1-734.055,234.412Zm4.785-5.811c0-.684.009-1.368,0-2.052a.75.75,0,0,0-.791-.845c-.518,0-.808.314-.809.9q0,3.868,0,7.736a1.673,1.673,0,0,0,0,.168.759.759,0,0,0,.958.725.739.739,0,0,0,.639-.846c0-1.3,0-2.6,0-3.9Q-729.27,229.543-729.27,228.6Z"
                                      transform="translate(734.058 -224.102)" fill="rgb(25,25,112)"/>
                                </svg>

                            </span>
                            <span className="text">1234</span>
                        </div>
                    </div>

                    <div className="field-row">
                        <Form.Item
                            name="bankRouting"
                            label="Routing Number"
                            rules={[
                                {required: true},
                                {min: 8, message: 'Routing Number must be minimum 6 characters.'},
                                {max: 50, message: 'Routing Number must be maximum 20 characters.'}
                            ]}
                        >
                            <Input
                                onFocus={handleFieldFocus}
                                onBlur={handleFieldFocusOut}
                                placeholder="110000000"
                            />
                        </Form.Item>
                    </div>

                    <div className="field-row">
                        <Form.Item>
                            <Form.Item
                                name="bankAccount"
                                label="Account Number"
                                rules={[
                                    {required: true},
                                    {min: 8, message: 'Account Number must be minimum 6 characters.'},
                                    {max: 50, message: 'Account Number must be maximum 20 characters.'}
                                ]}
                            >
                                <Input
                                    onFocus={handleFieldFocus}
                                    onBlur={handleFieldFocusOut}
                                    placeholder="110000000"
                                />
                            </Form.Item>
                            <Text className="text-info">
                                Your bank account must be a checking account
                            </Text>
                        </Form.Item>
                    </div>

                    <div className="field-row">
                        <Form.Item
                            name="reBankAccount"
                            label="Confirm Account Number"
                            rules={[
                                {required: true},
                                {min: 8, message: 'Confirm Account Number must be minimum 6 characters.'},
                                {max: 50, message: 'Confirm Account Number must be maximum 20 characters.'}
                            ]}
                        >
                            <Input
                                onFocus={handleFieldFocus}
                                onBlur={handleFieldFocusOut}
                                placeholder="110000000"
                            />
                        </Form.Item>
                    </div>

                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="btn btn-big whit-border border-color-pink bg-pink color-white"
                            >
                                Update
                            </Button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </>
    );
}

export default BankTransferPayment;
