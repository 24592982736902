/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import {
    authHeader,
    errorMessage,
} from "../../../../../_helpers";
import Table from 'antd/lib/table';
import Spin from 'antd/lib/spin';
import Breadcrumb from "antd/lib/breadcrumb";
import {Link} from "react-router-dom";

const apiUrl = process.env.REACT_APP_API;

function ProductsSettingsList() {
    let unmounted = useRef(false);
    const [state, setState] = useState({
        list: {},
        loader: true,
    })

    useEffect(() => {
        document.body.className = 'page-settings page-settings-products view-list';
        getList().then(res => {
            let {code, payload, message} = res
            if (code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: payload,
                        }
                    })
                }
            } else {
                if (!unmounted.current) {
                    return errorMessage(message)
                }
            }
        });
        return () => {
            document.body.className = '';
            unmounted.current = true
        }
    }, []);

    const getList = async () => {

        let res = await axios.get(`${apiUrl}/dashboard/settings/products`, {
            headers: authHeader()
        });

        let {data} = res;

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: 200,
                payload: data.payload
            };
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: data.error.code,
                message: data.error.message
            };
        }
    }


    const columns = [
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Action',
            key: 'action',
            className: 'action-wrap',
            width: 100,
            render: (item) => (
                <div className="action-box custom-button">
                    <Link className="ant-btn ant-btn-gray" to={`/settings/product/edit/${item.action}`}>
                        Edit
                    </Link>
                </div>
            ),
        },
    ];

    let listDataSource = []

    Object.keys(state.list).map((key) => {
        let {id, label, price, discount} = state.list[key]

        listDataSource[key] = {
            key: id,
            label,
            price: `$${price}`,
            discount: `$${discount}`,
            action: id,
        }
        return listDataSource;
    })

    let {loader} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }
            <Breadcrumb>
                <Breadcrumb.Item>
                    <svg className="icon-settings" xmlns="http://www.w3.org/2000/svg" width="15"
                         height="15.002"
                         viewBox="0 0 15 15.002">
                        <path
                            d="M17.784,9.361l-1.677-.5A5.823,5.823,0,0,0,15.632,7.7l.821-1.535A.3.3,0,0,0,16.4,5.8L15.2,4.6a.305.305,0,0,0-.36-.055l-1.526.815a5.818,5.818,0,0,0-1.181-.5l-.5-1.655a.305.305,0,0,0-.3-.205H9.647a.305.305,0,0,0-.29.215l-.5,1.65a5.823,5.823,0,0,0-1.191.5l-1.5-.81a.305.305,0,0,0-.36.055L4.587,5.8a.3.3,0,0,0-.055.36l.811,1.5a5.813,5.813,0,0,0-.5,1.185l-1.657.5a.305.305,0,0,0-.215.29v1.69a.305.305,0,0,0,.215.29l1.667.5a5.808,5.808,0,0,0,.5,1.165l-.821,1.57a.3.3,0,0,0,.055.36l1.2,1.2a.305.305,0,0,0,.36.055l1.547-.825a5.834,5.834,0,0,0,1.151.47l.5,1.685a.305.305,0,0,0,.29.215h1.692a.305.305,0,0,0,.29-.215l.5-1.69a5.823,5.823,0,0,0,1.141-.47l1.557.83a.305.305,0,0,0,.36-.055l1.2-1.2a.3.3,0,0,0,.055-.36l-.831-1.55a5.813,5.813,0,0,0,.475-1.145l1.687-.5a.305.305,0,0,0,.215-.29V9.656a.305.305,0,0,0-.185-.3Zm-7.292,3.89a2.75,2.75,0,1,1,2.753-2.75,2.751,2.751,0,0,1-2.753,2.75Z"
                            transform="translate(-2.97 -3)"/>
                    </svg>
                    <span>Products</span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <Table pagination={false} columns={columns} dataSource={listDataSource}/>

        </>
    );
}

export default ProductsSettingsList;
