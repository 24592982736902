/* eslint-disable no-template-curly-in-string */
import React, {useState} from 'react';
import Menu from 'antd/lib/menu';
import {NavLink} from "react-router-dom";

const {SubMenu} = Menu;
let pathName = window.location.pathname;
let pathElement = pathName.split('/');
const DashboardSidebar = () => {
    const [key] = useState(pathElement[1]);

    let defaultSelected
    if (key === 'settings') {
        let settingsSubKes = pathElement[2]
        switch (settingsSubKes) {
            case "product" :
                defaultSelected = "settings-product"
                break;
            case "counter" :
                defaultSelected = "settings-counter"
                break;
            case "announced-emails" :
                defaultSelected = "settings-announced-emails"
                break;
            case "attendance-requests" :
                defaultSelected = "settings-attendance-requests"
                break;
            case "featured-centers" :
                defaultSelected = "settings-featured-centers"
                break;
            default:
                defaultSelected = "settings-general"
        }
    } else {
        defaultSelected = `${key}-list`
    }

    return (
        <div id="mainSidebar" className="global-sidebar">
            <div className="sidebar-wrap">
                <Menu
                    mode="vertical"
                    defaultSelectedKeys={[defaultSelected]}
                >

                    <Menu.Item key="center-list">
                        <NavLink activeClassName="active" to="/center/list">
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
                                    <g transform="translate(0 0)">
                                        <path id="Path_3206" data-name="Path 3206"
                                              d="M24.373,13.5V6.722a.636.636,0,0,0-.632-.641h-3V4.237a.482.482,0,0,0-.243-.42L18.059,2.441l-.041-.02V1.634H19.33a.191.191,0,0,0,.19-.192V.471a.191.191,0,0,0-.19-.192H18.018V.192a.19.19,0,1,0-.379,0V2.42l-.041.02L15.16,3.818a.481.481,0,0,0-.243.42V6.082h-3a.636.636,0,0,0-.632.641V13.5A.786.786,0,0,0,11.6,15H24.054a.786.786,0,0,0,.319-1.5ZM21.929,8.314a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373H22.3a.371.371,0,0,1-.368-.373v-.7Zm0,2.242a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373H22.3a.371.371,0,0,1-.368-.373v-.7ZM19.717,8.314a.371.371,0,0,1,.368-.373h.689a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373h-.689a.371.371,0,0,1-.368-.373Zm0,2.242a.371.371,0,0,1,.368-.373h.689a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373h-.689a.371.371,0,0,1-.368-.373ZM17.828,4.239A1.057,1.057,0,1,1,16.785,5.3,1.05,1.05,0,0,1,17.828,4.239Zm-1.044,6.067a.371.371,0,0,1,.368-.373H18.5a.371.371,0,0,1,.368.373v2.94a.374.374,0,0,1-.049.185H16.832a.374.374,0,0,1-.049-.185v-2.94Zm-2.27-1.992a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373h-.688a.371.371,0,0,1-.368-.373Zm0,2.242a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373h-.688a.371.371,0,0,1-.368-.373ZM12.3,8.314a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373H12.67a.371.371,0,0,1-.368-.373v-.7Zm0,2.242a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373H12.67a.371.371,0,0,1-.368-.373v-.7Z"
                                              transform="translate(-10.828)" fill="#11289c"/>
                                    </g>
                                </svg>
                            </span>
                            <span className="label">Center</span>
                        </NavLink>
                    </Menu.Item>

                    <Menu.Item key="student-list">
                        <NavLink activeClassName="active" to="/student/list">
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12">
                                    <path
                                        d="M16,11.5a.5.5,0,0,1-1,0V4.922L9.484,7.688A3.284,3.284,0,0,1,8,8a3.284,3.284,0,0,1-1.484-.312L.609,4.734Q0,4.422,0,3.992t.609-.726L6.516.312A3.284,3.284,0,0,1,8,0,3.284,3.284,0,0,1,9.484.312l5.906,2.953a.95.95,0,0,1,.594.656A.091.091,0,0,1,16,3.985V11.5ZM9.984,8.688,13,7.172v2.2q0,.672-1.461,1.148A11.5,11.5,0,0,1,8,11a11.5,11.5,0,0,1-3.539-.476Q3,10.047,3,9.375v-2.2L6.016,8.688A4.692,4.692,0,0,0,8,9a4.692,4.692,0,0,0,1.984-.312Z"
                                        fill="#11289c"/>
                                </svg>
                            </span>
                            <span className="label">Student</span>
                        </NavLink>
                    </Menu.Item>

                    <Menu.Item key="result-list">
                        <NavLink activeClassName="active" to="/result/list">
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10.629"
                                     viewBox="0 0 15 10.629">
                                  <g transform="translate(-2.842 -7.258)">
                                    <path
                                        d="M24.174,7.573a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0V8.7c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314V7.572Z"
                                        transform="translate(-6.332)" fill="#11289c"/>
                                    <path
                                        d="M24.174,12.938a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0v1.13c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314Z"
                                        transform="translate(-6.332 -2.711)" fill="#11289c"/>
                                    <path
                                        d="M7.268,7.573a.314.314,0,0,0-.314-.314l-.022,0H3.177l-.022,0a.314.314,0,0,0-.314.314v3.8a.314.314,0,0,0,.314.314h3.8a.314.314,0,0,0,.314-.314v-3.8Z"
                                        transform="translate(0 -0.001)" fill="#11289c"/>
                                    <path
                                        d="M24.174,20.107a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0v1.13c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314V20.106Z"
                                        transform="translate(-6.332 -6.333)" fill="#11289c"/>
                                    <path
                                        d="M24.174,25.471a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0V26.6c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314Z"
                                        transform="translate(-6.332 -9.043)" fill="#11289c"/>
                                    <path
                                        d="M7.268,20.106a.314.314,0,0,0-.314-.314l-.022,0H3.177l-.022,0a.314.314,0,0,0-.314.314v3.8a.314.314,0,0,0,.314.314h3.8a.314.314,0,0,0,.314-.314Z"
                                        transform="translate(0 -6.333)" fill="#11289c"/>
                                  </g>
                                </svg>
                            </span>
                            <span className="label">Results</span>
                        </NavLink>
                    </Menu.Item>

                    <SubMenu
                        key="settings"
                        title={
                            <>
                                <span className="icon">
                                    <svg className="icon-settings" xmlns="http://www.w3.org/2000/svg" width="15"
                                         height="15.002"
                                         viewBox="0 0 15 15.002">
                                        <path
                                            d="M17.784,9.361l-1.677-.5A5.823,5.823,0,0,0,15.632,7.7l.821-1.535A.3.3,0,0,0,16.4,5.8L15.2,4.6a.305.305,0,0,0-.36-.055l-1.526.815a5.818,5.818,0,0,0-1.181-.5l-.5-1.655a.305.305,0,0,0-.3-.205H9.647a.305.305,0,0,0-.29.215l-.5,1.65a5.823,5.823,0,0,0-1.191.5l-1.5-.81a.305.305,0,0,0-.36.055L4.587,5.8a.3.3,0,0,0-.055.36l.811,1.5a5.813,5.813,0,0,0-.5,1.185l-1.657.5a.305.305,0,0,0-.215.29v1.69a.305.305,0,0,0,.215.29l1.667.5a5.808,5.808,0,0,0,.5,1.165l-.821,1.57a.3.3,0,0,0,.055.36l1.2,1.2a.305.305,0,0,0,.36.055l1.547-.825a5.834,5.834,0,0,0,1.151.47l.5,1.685a.305.305,0,0,0,.29.215h1.692a.305.305,0,0,0,.29-.215l.5-1.69a5.823,5.823,0,0,0,1.141-.47l1.557.83a.305.305,0,0,0,.36-.055l1.2-1.2a.3.3,0,0,0,.055-.36l-.831-1.55a5.813,5.813,0,0,0,.475-1.145l1.687-.5a.305.305,0,0,0,.215-.29V9.656a.305.305,0,0,0-.185-.3Zm-7.292,3.89a2.75,2.75,0,1,1,2.753-2.75,2.751,2.751,0,0,1-2.753,2.75Z"
                                            transform="translate(-2.97 -3)"/>
                                    </svg>
                                </span>
                                <span className="label">Settings</span>
                            </>
                        }
                    >
                        <Menu.Item key="settings-general">
                            <NavLink activeClassName="active" to="/settings/general/list">
                                General Settings
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="settings-product">
                            <NavLink activeClassName="active" to="/settings/product/list">
                                Products
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="settings-counter">
                            <NavLink activeClassName="active" to="/settings/counter/list">
                                Counters
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="settings-announced-emails">
                            <NavLink activeClassName="active" to="/settings/announced-emails">
                                Announced Email Addresses
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="settings-attendance-requests">
                            <NavLink activeClassName="active" to="/settings/attendance-requests/list">
                                Attendance Requests
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="settings-featured-centers">
                            <NavLink activeClassName="active" to="/settings/featured-centers/list">
                                Featured Centers
                            </NavLink>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </div>
        </div>
    )
}

export default DashboardSidebar;
