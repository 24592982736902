/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import axios from "axios";
import {
    errorMessage, getLocalStorage, successMessage,
} from "../../../../../_helpers";
import Space from 'antd/lib/space';
import Spin from 'antd/lib/spin';
import Breadcrumb from "antd/lib/breadcrumb";
import Form from "antd/lib/form";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Image from 'antd/lib/image'
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';

const {TextArea} = Input;
const apiUrl = process.env.REACT_APP_API;

function FeaturedCenterCreate() {
    const [form] = Form.useForm();
    const user = getLocalStorage('user', true)
    const [state, setState] = useState({
        loader: false,
        uploaderLoader: false,
        centerLogo: '',
        file: ''
    })

    const validateMessages = {
        required: '${label} is required!',
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        document.body.className = 'page-settings page-settings-featured view-create';

        return () => {
            document.body.className = '';
        }
    }, []);

    const Create = async values => {
        const {subTitle, title, url} = values
        const {file} = state
        const postData = new FormData();

        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        postData.append('image', file);
        postData.append('title', title);
        postData.append('subTitle', subTitle);
        postData.append('url', url);

        let res = await axios.post(`${apiUrl}/dashboard/settings/featuredcenters`, postData, {
            headers: {
                'content-type': 'multipart/form-data',
                'authorization': user.token
            }
        });
        let {data} = res;

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })
            successMessage('Featured Center successfully created').then(res => {
                if (res) {
                    window.location.href = `/settings/featured-centers/edit/${data.payload}`
                }
            })
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })
            return errorMessage(data.error.message)
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChange = async e => {
        let filesArr = e.target.files
        let file = filesArr[0]
        setState(prevState => {
            return {
                ...prevState,
                uploaderLoader: true
            }
        })

        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJpgOrPng || !isLt2M) {
            setState(prevState => {
                return {
                    ...prevState,
                    uploaderLoader: false
                }
            })
            if (!isJpgOrPng) {
                await errorMessage('You can only upload JPG/PNG file!')
            }
            if (!isLt2M) {
                await errorMessage('Image must smaller than 2MB!')
            }
            return false
        }

        getBase64(file, imageUrl => {
                setState(prevState => {
                    return {
                        ...prevState,
                        uploaderLoader: false,
                        centerLogo: imageUrl,
                        file
                    }
                })
            }
        );
    };

    let {loader, uploaderLoader, centerLogo} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }
            <Breadcrumb>
                <Breadcrumb.Item href="/settings/featured-centers/list">
                    <svg className="icon-settings" xmlns="http://www.w3.org/2000/svg" width="15"
                         height="15.002"
                         viewBox="0 0 15 15.002">
                        <path
                            d="M17.784,9.361l-1.677-.5A5.823,5.823,0,0,0,15.632,7.7l.821-1.535A.3.3,0,0,0,16.4,5.8L15.2,4.6a.305.305,0,0,0-.36-.055l-1.526.815a5.818,5.818,0,0,0-1.181-.5l-.5-1.655a.305.305,0,0,0-.3-.205H9.647a.305.305,0,0,0-.29.215l-.5,1.65a5.823,5.823,0,0,0-1.191.5l-1.5-.81a.305.305,0,0,0-.36.055L4.587,5.8a.3.3,0,0,0-.055.36l.811,1.5a5.813,5.813,0,0,0-.5,1.185l-1.657.5a.305.305,0,0,0-.215.29v1.69a.305.305,0,0,0,.215.29l1.667.5a5.808,5.808,0,0,0,.5,1.165l-.821,1.57a.3.3,0,0,0,.055.36l1.2,1.2a.305.305,0,0,0,.36.055l1.547-.825a5.834,5.834,0,0,0,1.151.47l.5,1.685a.305.305,0,0,0,.29.215h1.692a.305.305,0,0,0,.29-.215l.5-1.69a5.823,5.823,0,0,0,1.141-.47l1.557.83a.305.305,0,0,0,.36-.055l1.2-1.2a.3.3,0,0,0,.055-.36l-.831-1.55a5.813,5.813,0,0,0,.475-1.145l1.687-.5a.305.305,0,0,0,.215-.29V9.656a.305.305,0,0,0-.185-.3Zm-7.292,3.89a2.75,2.75,0,1,1,2.753-2.75,2.751,2.751,0,0,1-2.753,2.75Z"
                            transform="translate(-2.97 -3)"/>
                    </svg>
                    <span>Featured Centers</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
            </Breadcrumb>

            <Form
                form={form}
                name="featured-center-create"
                onFinish={Create}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
            >
                <div className="form-box">
                    <div className="field-row">
                        <Form.Item
                            name="logo"
                            label={
                                <>
                                    <span>Center Logo</span>
                                    <span> (300x300)</span>
                                </>
                            }
                            rules={[{required: true}]}>
                            <Space>
                                <input className="hidden" type="file" id="centerLogo"
                                       value={''} onChange={handleChange}/>
                                <label className={`avatar-label`}
                                       htmlFor="centerLogo">
                                    {centerLogo ?
                                        <Image preview={false} src={centerLogo} alt="avatar"
                                               style={{width: '100%'}}/> : (
                                            <div className="wrap">
                                                {uploaderLoader ? <LoadingOutlined/> : <PlusOutlined/>}
                                                <div style={{marginTop: 8}}>Upload</div>
                                            </div>
                                        )}
                                </label>
                            </Space>
                        </Form.Item>
                    </div>
                    <div className="field-row">
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[{required: true}]}>
                            <Input maxLength={60} placeholder="Write Title"/>
                        </Form.Item>
                    </div>
                    <div className="field-row">
                        <Form.Item
                            name="url"
                            label="Center Website">
                            <Input placeholder="Write Center Website Url"/>
                        </Form.Item>
                    </div>
                    <div className="field-row">
                        <Form.Item
                            name="subTitle"
                            label="Description"
                            rules={[{required: true}]}>
                            <TextArea showCount maxLength={100} placeholder="Write Description"/>
                        </Form.Item>
                    </div>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="button"
                            >
                                Create
                            </Button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </>
    );
}

export default FeaturedCenterCreate;
