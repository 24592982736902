/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import {
    authHeader,
} from "../../../../_helpers";
import Table from 'antd/lib/table';
import Pagination from 'antd/lib/pagination';
import Tag from 'antd/lib/tag';
import Spin from 'antd/lib/spin';
import Breadcrumb from "antd/lib/breadcrumb";
import {Link} from "react-router-dom";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import fileDownload from "js-file-download";

const {Search} = Input;
const moment = require("moment");
const apiUrl = process.env.REACT_APP_API;

function CenterList() {
    let unmounted = useRef(false);
    const [state, setState] = useState({
        list: [],
        listTotal: 0,
        currentPage: 1,
        pp: 10,
        loader: true,
        searchValue: ''
    })
    useEffect(() => {
        document.body.className = 'page-center view-list';
        getList().then(res => {
            let {code, payload} = res
            if (code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: payload.list,
                            listTotal: payload.count
                        }
                    })
                }
            }
        });
        return () => {
            document.body.className = '';
            unmounted.current = true
        }
    }, []);

    const getList = async (start = 0, end = 10, search = '') => {
        const queryParams = {};
        queryParams._start = start;
        queryParams._end = end;
        queryParams.search = search
        let res = await axios.get(`${apiUrl}/dashboard/centers/list`, {
            params: queryParams,
            headers: authHeader()
        });

        const {data} = res;

        if (data.error === null) {
            let {count, rows} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            };
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: data.error.code,
                message: data.error.message
            };
        }
    }

    const generateCsv = async () => {
        const res = await axios.get(`${apiUrl}/dashboard/centers/export`, {
            headers: authHeader(),
            responseType: 'blob'
        })

        const {data} = res
        if (data) {
            fileDownload(data, 'Centers-list.csv')
        }
    }

    const onSearch = value => {
        let {pp} = state;

        setState(prevState => {
            return {
                ...prevState,
                searchValue: value,
                loader: true,
            }
        })
        getList(0, pp, value).then(res => {
            if (res.code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: res.payload.list,
                            listTotal: res.payload.count,
                            currentPage: 1
                        }
                    })
                }
            }
        })
    }

    const columns = [
        {
            title: 'Center Name',
            dataIndex: 'centerName',
            key: 'centerName',
        },
        {
            title: 'Contact Email',
            dataIndex: 'contactEmail',
            key: 'contactEmail',
        },
        {
            title: 'Contact Full Name',
            dataIndex: 'contactFullName',
            key: 'contactFullName',
        },
        {
            title: 'Location',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Created Date',
            dataIndex: 'createddate',
            key: 'createddate',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',

            render: (status) => {
                let color = status === true ? 'green' : 'red'
                let tag = status === true ? 'Active' : 'Passive'
                return (
                    <Tag color={color}>
                        {tag.toUpperCase()}
                    </Tag>
                )
            },
        },

        {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            render: (verified) => {
                let color = verified === true ? 'green' : 'red'
                let tag = verified === true ? 'Verified' : 'Not Verified'
                return (
                    <Tag color={color}>
                        {tag.toUpperCase()}
                    </Tag>
                )
            },
        },
        {
            title: 'Action',
            key: 'action',
            className: 'action-wrap',
            width: 100,
            render: (item) => (
                <div className="action-box custom-button">
                    <Link className="ant-btn ant-btn-gray" to={`/center/edit/${item.action}`}>
                        Edit
                    </Link>
                </div>
            ),
        },
    ];
    let listDataSource = []
    Object.keys(state.list).map((key) => {
        let {
            id,
            centerName,
            contactEmail,
            contactFullName,
            countryName,
            state: province,
            city,
            createdAt,
            isActive,
            isVerified,
        } = state.list[key]

        listDataSource[key] = {
            key: id,
            centerName,
            contactEmail,
            contactFullName,
            address: `${city}, ${province}, ${countryName}`,
            // createddate: moment(createdAt, "YYYY-MM-DD").format("ddd, MMM D"),
            createddate: moment(createdAt, "YYYY-MM-DDTh:mm").format("ddd, MMM D, H:mm"),
            action: id,
            status: isActive,
            verified: isVerified
        }
        return listDataSource;
    })

    const onChange = page => {
        let {searchValue, pp} = state;
        let start = (page - 1) * pp,
            end = pp * page;

        setState(prevState => {
            return {
                ...prevState,
                currentPage: page,
                loader: true,
            }
        })

        getList(start, end, searchValue).then(res => {
            if (res.code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: res.payload.list,
                            listTotal: res.payload.count
                        }
                    })
                }
            }
        })
    }

    let {loader, pp, currentPage, listTotal} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }
            <Breadcrumb>
                <Breadcrumb.Item>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
                        <g transform="translate(0 0)">
                            <path id="Path_3206" data-name="Path 3206"
                                  d="M24.373,13.5V6.722a.636.636,0,0,0-.632-.641h-3V4.237a.482.482,0,0,0-.243-.42L18.059,2.441l-.041-.02V1.634H19.33a.191.191,0,0,0,.19-.192V.471a.191.191,0,0,0-.19-.192H18.018V.192a.19.19,0,1,0-.379,0V2.42l-.041.02L15.16,3.818a.481.481,0,0,0-.243.42V6.082h-3a.636.636,0,0,0-.632.641V13.5A.786.786,0,0,0,11.6,15H24.054a.786.786,0,0,0,.319-1.5ZM21.929,8.314a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373H22.3a.371.371,0,0,1-.368-.373v-.7Zm0,2.242a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373H22.3a.371.371,0,0,1-.368-.373v-.7ZM19.717,8.314a.371.371,0,0,1,.368-.373h.689a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373h-.689a.371.371,0,0,1-.368-.373Zm0,2.242a.371.371,0,0,1,.368-.373h.689a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373h-.689a.371.371,0,0,1-.368-.373ZM17.828,4.239A1.057,1.057,0,1,1,16.785,5.3,1.05,1.05,0,0,1,17.828,4.239Zm-1.044,6.067a.371.371,0,0,1,.368-.373H18.5a.371.371,0,0,1,.368.373v2.94a.374.374,0,0,1-.049.185H16.832a.374.374,0,0,1-.049-.185v-2.94Zm-2.27-1.992a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373h-.688a.371.371,0,0,1-.368-.373Zm0,2.242a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373h-.688a.371.371,0,0,1-.368-.373ZM12.3,8.314a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373H12.67a.371.371,0,0,1-.368-.373v-.7Zm0,2.242a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373H12.67a.371.371,0,0,1-.368-.373v-.7Z"
                                  transform="translate(-10.828)" fill="#11289c"/>
                        </g>
                    </svg>
                    <span>Center</span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className="tool-box">
                <div className="filter-box">
                    <div className="field-box">
                        <Search
                            autoComplete={new Date().valueOf()}
                            placeholder="Search by Name"
                            onSearch={onSearch}/>
                    </div>
                </div>
                <div className="action-box">
                    <Button
                        type="primary"
                        className="button"
                        onClick={generateCsv}
                    >
                        Generate CSV
                    </Button>
                </div>
            </div>
            <Table pagination={false} columns={columns} dataSource={listDataSource}/>

            <Pagination
                simple
                defaultPageSize={pp}
                className="ant-table-pagination ant-table-pagination-right"
                current={currentPage}
                onChange={onChange}
                total={listTotal}
            />
        </>
    );
}

export default CenterList;
