/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import {
    authHeader,
} from "../../../../../_helpers";
import Table from 'antd/lib/table';
import Pagination from 'antd/lib/pagination';
import Spin from 'antd/lib/spin';
import Input from "antd/lib/input";

const {Search} = Input;
const apiUrl = process.env.REACT_APP_API;
const moment = require("moment");

function StudentsList(props) {
    let {studentId, updateComponent} = props

    let unmounted = useRef(false);
    const [state, setState] = useState({
        list: {},
        listTotal: 0,
        currentPage: 1,
        pp: 10,
        loader: true,
        searchValue: ''
    })
    const [updateComponentState, setUpdateComponentState] = useState('');

    useEffect(() => {
        if (updateComponent && updateComponent !== updateComponentState) {
            setUpdateComponentState(updateComponent);
            setState(prevState => {
                return {
                    ...prevState,
                    list: {},
                    listTotal: 0,
                    currentPage: 1,
                    searchValue: ''
                }
            })
            getList().then(res => {
                let {code, payload} = res
                if (code === 200) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: payload.list,
                            listTotal: payload.count
                        }
                    })
                }
            });
        }
        getList().then(res => {
            let {code, payload} = res
            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        list: payload.list,
                        listTotal: payload.count
                    }
                })
            }
        });
        return () => {
            unmounted.current = true
        }
    }, [updateComponent, updateComponentState]);

    const getList = async (start = 0, end = 10, search = '') => {
        const queryParams = {
            _start: start,
            _end: end,
            search,
        };

        let res = await axios.get(`${apiUrl}/dashboard/students/list/`, {
            params: queryParams,
            headers: authHeader()
        });

        let {data} = res;

        if (data.error === null) {
            let {count, rows} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            };
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: data.error.code,
                message: data.error.message
            };
        }
    }

    const onChange = page => {
        let {searchValue, pp} = state;
        let start = (page - 1) * pp,
            end = pp * page;

        setState(prevState => {
            return {
                ...prevState,
                currentPage: page,
                loader: true,
            }
        })

        getList(start, end, searchValue).then(res => {
            if (res.code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        list: res.payload.list,
                        listTotal: res.payload.count
                    }
                })
            }
        })
    }

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        }
    ];

    let listDataSource = []

    Object.keys(state.list).map((key) => {
        let {
            id,
            firstName,
            lastName,
            email,
            parentFullName,
            createdAt,
            studentPurchase,
            isActive,
            isVerified
        } = state.list[key]

        listDataSource[key] = {
            key: id,
            firstName,
            lastName,
            email,
            parentFullName,
            studentPurchase,
            createddate: moment(createdAt, "YYYY-MM-DD").format("ddd, MMM D"),
            status: isActive,
            verified: isVerified
        }
        return listDataSource;
    })

    const onSearch = value => {
        let {pp} = state;

        setState(prevState => {
            return {
                ...prevState,
                searchValue: value,
                loader: true,
            }
        })
        getList(0, pp, value).then(res => {
            if (res.code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        list: res.payload.list,
                        listTotal: res.payload.count,
                        currentPage: 1
                    }
                })
            }
        })
    }

    let {loader, pp, currentPage, listTotal} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }
            <div className="tool-box">
                <div className="filter-box">
                    <div className="field-box">
                        <Search
                            autoComplete={new Date().valueOf()}
                            placeholder="Search by Name"
                            onSearch={onSearch}/>
                    </div>
                </div>
                <div className="action-box"/>
            </div>

            <Table rowClassName={(record) => {
                if (record.key === studentId) {
                    return 'ant-table-row-selected'
                }
            }}
                   onRow={(record) => {
                       return {
                           onClick: event => {
                               props.handleStudentSelect(record)
                               let rows = document.querySelectorAll("tr[data-row-key]")
                               let currentRow = document.querySelector(`tr[data-row-key="${record.key}"]`)
                               // eslint-disable-next-line array-callback-return
                               Object.keys(rows).map((key) => {
                                   let row = rows[key]
                                   if (row.classList.contains('ant-table-row-selected')) {
                                       row.classList.remove('ant-table-row-selected')
                                   }
                               })
                               if (!currentRow.classList.contains('ant-table-row-selected')) {
                                   currentRow.classList.add('ant-table-row-selected')
                               }
                           }
                       };
                   }}
                   scroll={{y: 350}} pagination={false} columns={columns} dataSource={listDataSource}/>

            <Pagination
                simple
                defaultPageSize={pp}
                className="ant-table-pagination ant-table-pagination-right"
                current={currentPage}
                onChange={onChange}
                total={listTotal}
            />
        </>
    );
}

export default StudentsList;
