/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import {
    authHeader,
    errorMessage,
} from "../../../../../_helpers";
import Table from 'antd/lib/table';
import Tag from 'antd/lib/tag';
import Spin from 'antd/lib/spin';
import {Link} from "react-router-dom";

const apiUrl = process.env.REACT_APP_API;
const moment = require("moment");

function StudentsPaymentsList(props) {
    const {studentId} = props
    let unmounted = useRef(false);
    const [state, setState] = useState({
        list: [],
        loader: true,
    })

    useEffect(() => {
        document.body.className = 'page-student view-list';
        getList(studentId).then(res => {
            let {code, payload, message} = res
            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        list: payload,
                        loader: false
                    }
                })
            } else {
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false
                    }
                })
                return errorMessage(message)
            }
        });
        return () => {
            document.body.className = '';
            unmounted.current = true
        }
    }, [studentId]);

    const getList = async (id) => {

        let res = await axios.get(`${apiUrl}/dashboard/students/payments/${id}`, {
            headers: authHeader()
        });

        const {data} = res;
        if (data.error === null) {
            return {
                code: 200,
                payload: data.payload
            }
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })
            return {
                code: 400,
                message: data.error.message
            };
        }
    }

    const columns = [
        {
            title: 'Transaction Id',
            dataIndex: 'transactionId',
            key: 'transactionId',
        },
        {
            title: 'Charge Id',
            dataIndex: 'chargeId',
            key: 'chargeId',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Product Id',
            key: 'productId',
            render: (item) => {
                return (
                    <Link to={`/settings/product/edit/${item.productId}`} target="_blank">
                        {`#${item.productId}`}
                    </Link>
                )
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createddate',
            key: 'createddate',
        },
        {
            title: 'Status',
            key: 'status',
            width: 102,
            render: (item) => {
                const {status} = item
                return (
                    <Tag color={status === "succeeded" ? "green" : "red"}>
                        {status.toUpperCase()}
                    </Tag>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            className: 'action-wrap',
            width: 100,
            render: (item) => (
                <div className="action-box custom-button">
                    <a rel="noreferrer" className="ant-btn ant-btn-gray" target="_blank"
                       href={item.receiptUrl}>Receipt</a>
                </div>
            ),
        },
    ];

    let listDataSource = []

    let {loader, list} = state

    Object.keys(list).map((key) => {
        let {id, transactionId, price, productId, receiptUrl, chargeId, createdAt, status} = list[key]

        listDataSource[key] = {
            key: id,
            transactionId,
            price: `$${price}`,
            productId,
            receiptUrl,
            chargeId,
            // createddate: moment(createdAt, "YYYY-MM-DD").format("ddd, MMM D"),
            createddate: moment(createdAt, "YYYY-MM-DDTh:mm").format("ddd, MMM D, H:mm"),
            status
        }
        return listDataSource;
    })

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }

            <Table pagination={false} columns={columns} dataSource={listDataSource}/>
        </>
    );
}

export default StudentsPaymentsList;
