import React from "react";
import DashboardSidebar from "./components/partials/sidebar";
import {Route, Switch, Redirect} from "react-router-dom";

import routes from "./routes";

function MainWrapper() {
    return (
        <div className="site-wrapper">
            <DashboardSidebar/>

            <div className="main-box">
                <Switch>
                    {routes.map((route, idx) => {
                        return route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.component {...props} key={new Date().getTime()}/>
                                )}
                            />

                        ) : null;
                    })}
                    <Redirect to={{pathname: "/center/list"}}/>
                </Switch>
            </div>
        </div>
    )
}

export default MainWrapper;
