/* eslint-disable no-template-curly-in-string */
import React, {useState, useEffect, useRef} from 'react';
import Switch from 'antd/lib/switch';
import Spin from 'antd/lib/spin';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Breadcrumb from 'antd/lib/breadcrumb'
import Tabs from 'antd/lib/tabs';

import Empty from 'antd/lib/empty'
import axios from "axios";
import {
    authHeader,
    errorMessage,
    successMessage
} from "../../../../_helpers";
import PhoneInput from "react-phone-input-2";
import Tooltip from "antd/lib/tooltip";
import Select from "antd/lib/select";
import StudentsList from "../student/list/index"
import PaymentComponent from "../payment/index"

const apiUrl = process.env.REACT_APP_API;
const {TabPane} = Tabs;

function UpdateCenter(props) {
    let {centerId} = props.match.params
    let unmounted = useRef(false);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loader: true,
        empty: true,
        CountriesList: [],
        GradesList: [],
        CentersList: [],
        centerField: false,
    })

    useEffect(() => {
        document.body.className = 'page-center view-edit';
        getCountriesList().then(res => {
            if (res) {
                setState(prevState => {
                    return {
                        ...prevState,
                        CountriesList: res,
                    }
                })
            }
        })
        getDataById(centerId).then(res => {
            if (res.code === 200) {
                const data = res.payload

                const {
                    address1,
                    address2,
                    centerName,
                    city,
                    contactEmail,
                    contactFullName,
                    countryId,
                    isActive,
                    isVerified,
                    phone,
                    state,
                    zipCode,
                    howYouHeard,
                    website
                } = data

                form.setFieldsValue({
                    address1,
                    address2,
                    centerName,
                    city,
                    email: contactEmail,
                    contactFullName,
                    countryId,
                    status: isActive,
                    verified: isVerified,
                    contactPhone: phone,
                    state,
                    zipCode,
                    website,
                    howYouHeard
                });
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                        empty: false,
                    }
                })
            } else {
                setState(prevState => {
                    return {
                        ...prevState,
                        empty: true,
                        loader: false
                    }
                })
                errorMessage(res.message).then(res => {
                    if (res) {
                        window.location.href = "/center/list";
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }
        });
        return () => {
            document.body.className = '';
            unmounted.current = true;
        }
    }, [
        form,
        centerId
    ]);

    const validateMessages = {
        required: '${label} is required!',
    };

    const getDataById = async (id) => {
        let res = await axios.get(`${apiUrl}/dashboard/centers/${id}`, {
            headers: authHeader()
        });
        let {data} = res;

        if (data.error === null) {
            if (data.payload === null) {
                return {
                    code: 400,
                    message: 'Something went wrong'
                }
            }
            return {
                code: 200,
                payload: data.payload
            }
        } else {
            return {
                code: 400,
                message: data.error.message
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const getCountriesList = async () => {
        let queryParams = {}
        queryParams._start = 0
        queryParams._end = 250
        queryParams.search = ''

        let res = await axios.get(`${apiUrl}/countries/list`, {
            params: queryParams
        });
        let {data} = res;

        if (data.error === null) {
            return data.payload
        }
    }

    const Update = async v => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        const {
            address1,
            address2,
            centerName,
            city,
            contactFullName,
            countryId,
            status,
            verified,
            contactPhone,
            state,
            zipCode,
            website
        } = v

        const postData = {
            address1,
            address2,
            centerName,
            city,
            contactFullName,
            countryId,
            isActive: status,
            isVerified: verified,
            phone: contactPhone,
            state,
            zipCode,
            website
        };

        const res = await axios.put(`${apiUrl}/dashboard/centers/${centerId}`, postData, {
            headers: authHeader()
        });
        const {data} = res;
        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })
            return successMessage('Center successfully Updated');
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })
            return errorMessage(data.error.message)
        }
    }
    let {empty, loader, CountriesList} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }
            {
                empty ? (
                    <>
                        <Empty/>
                    </>
                ) : (
                    <>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/center/list">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
                                    <g transform="translate(0 0)">
                                        <path id="Path_3206" data-name="Path 3206"
                                              d="M24.373,13.5V6.722a.636.636,0,0,0-.632-.641h-3V4.237a.482.482,0,0,0-.243-.42L18.059,2.441l-.041-.02V1.634H19.33a.191.191,0,0,0,.19-.192V.471a.191.191,0,0,0-.19-.192H18.018V.192a.19.19,0,1,0-.379,0V2.42l-.041.02L15.16,3.818a.481.481,0,0,0-.243.42V6.082h-3a.636.636,0,0,0-.632.641V13.5A.786.786,0,0,0,11.6,15H24.054a.786.786,0,0,0,.319-1.5ZM21.929,8.314a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373H22.3a.371.371,0,0,1-.368-.373v-.7Zm0,2.242a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373H22.3a.371.371,0,0,1-.368-.373v-.7ZM19.717,8.314a.371.371,0,0,1,.368-.373h.689a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373h-.689a.371.371,0,0,1-.368-.373Zm0,2.242a.371.371,0,0,1,.368-.373h.689a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373h-.689a.371.371,0,0,1-.368-.373ZM17.828,4.239A1.057,1.057,0,1,1,16.785,5.3,1.05,1.05,0,0,1,17.828,4.239Zm-1.044,6.067a.371.371,0,0,1,.368-.373H18.5a.371.371,0,0,1,.368.373v2.94a.374.374,0,0,1-.049.185H16.832a.374.374,0,0,1-.049-.185v-2.94Zm-2.27-1.992a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373h-.688a.371.371,0,0,1-.368-.373Zm0,2.242a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373h-.688a.371.371,0,0,1-.368-.373ZM12.3,8.314a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373H12.67a.371.371,0,0,1-.368-.373v-.7Zm0,2.242a.371.371,0,0,1,.368-.373h.688a.371.371,0,0,1,.368.373v.7a.371.371,0,0,1-.368.373H12.67a.371.371,0,0,1-.368-.373v-.7Z"
                                              transform="translate(-10.828)" fill="#11289c"/>
                                    </g>
                                </svg>
                                <span>Center</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Edit</Breadcrumb.Item>
                        </Breadcrumb>

                        <Form
                            form={form}
                            name="center_update"
                            onFinish={Update}
                            onFinishFailed={onFinishFailed}
                            validateMessages={validateMessages}
                        >
                            <div className="form-box">
                                <div className="field-row half">

                                    <Form.Item
                                        name="centerName"
                                        label="Center Name"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            placeholder="Write Center name"
                                        />
                                    </Form.Item>

                                    <Tooltip placement="left" color={"#ea0101"}
                                             title={"You can't edit Email"}>
                                        <Form.Item
                                            name="email"
                                            label="Email"
                                            rules={[{required: true}]}>
                                            <Input disabled={true} placeholder="Email"/>
                                        </Form.Item>
                                    </Tooltip>
                                </div>
                                <div className="field-row half">
                                    <Form.Item
                                        name="contactFullName"
                                        label="Contact Person Full Name"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            placeholder="Write your full name"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={
                                            <>
                                                <span>Contact Person Phone</span>
                                                <span>(Optional)</span>
                                            </>
                                        }
                                        name="contactPhone"
                                    >
                                        <PhoneInput
                                            autoComplete={new Date().valueOf()}
                                            inputProps={{
                                                name: 'contactPhone',
                                            }}
                                            placeholder="Write your phone number"
                                            inputClass="ant-input"
                                            country={'us'}
                                            type="tel"
                                            tabIndex="3"
                                        />
                                    </Form.Item>
                                </div>

                                <div className="field-row half">
                                    <Form.Item
                                        label={
                                            <>
                                                <span>Website</span>
                                                <span>(Optional)</span>
                                            </>
                                        }
                                        name="website"
                                    >
                                        <Input placeholder="Learning center website"/>
                                    </Form.Item>
                                </div>

                                <div className="separator">
                                    <div className="separator-text">
                                        Center Address
                                    </div>
                                </div>

                                <div className="field-row half">
                                    <Form.Item
                                        name="countryId"
                                        label="Country"
                                        rules={[{required: true}]}
                                    >

                                        <Select
                                            virtual={false}
                                            autoComplete={new Date().valueOf()}
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Select Country"
                                            allowClear
                                            showArrow
                                        >
                                            {Object.keys(CountriesList).map((key) => {
                                                let listItem = CountriesList[key]
                                                return (
                                                    <Select.Option key={key}
                                                                   value={listItem.id}>{listItem.countryName}</Select.Option>
                                                )
                                            })}
                                        </Select>

                                    </Form.Item>

                                    <Form.Item
                                        label="Address line 1"
                                        name="address1"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            placeholder="Street Address"
                                        />
                                    </Form.Item>
                                </div>

                                <div className="field-row half">
                                    <Form.Item
                                        label="Address line 2"
                                        name="address2"
                                    >
                                        <Input
                                            placeholder="Apt, Suite Unit, Building"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="City"
                                        name="city"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            placeholder="Write your city"
                                        />
                                    </Form.Item>

                                </div>

                                <div className="field-row half">
                                    <Form.Item
                                        label="State/Province"
                                        name="state"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            placeholder="Write your state if applicable or N/A"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="ZIP"
                                        name="zipCode"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            placeholder="Write ZIP Code"
                                        />
                                    </Form.Item>
                                </div>

                                <div className="field-row half">

                                    <Form.Item
                                        label={
                                            <>
                                                <span>How you heard about us</span>
                                                <span>(Optional)</span>
                                            </>
                                        }
                                        name="howYouHeard"
                                    >
                                        <Input.TextArea disabled={true}
                                                        placeholder="Write how you heard about Space Panda Math Olympiad"
                                        />
                                    </Form.Item>

                                </div>

                                <div className="field-row operation">
                                    <Form.Item name="status" label="Status" valuePropName="checked">
                                        <Switch defaultChecked/>
                                    </Form.Item>

                                    <Form.Item name="verified" label="Email Verified" valuePropName="checked">
                                        <Switch defaultChecked/>
                                    </Form.Item>

                                </div>

                                <Form.Item shouldUpdate={true}>
                                    {() => (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="btn btn-big whit-border border-color-pink bg-pink color-white"
                                        >
                                            Update
                                        </Button>
                                    )}
                                </Form.Item>
                            </div>
                        </Form>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Student List" key="1">
                                <StudentsList centerId={centerId}/>
                            </TabPane>
                            <TabPane tab="Payout Options" key="2">
                                <PaymentComponent centerId={centerId}/>
                            </TabPane>
                        </Tabs>
                    </>
                )
            }
        </>
    );
}

export default UpdateCenter;
