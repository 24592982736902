/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";
import {
    authHeader,
    errorMessage,
} from "../../../../_helpers";
import Table from 'antd/lib/table';
import Pagination from 'antd/lib/pagination';
import Tag from 'antd/lib/tag';
import Spin from 'antd/lib/spin';
import Breadcrumb from "antd/lib/breadcrumb";
import {Link} from "react-router-dom";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import fileDownload from "js-file-download";

const {Search} = Input;
const apiUrl = process.env.REACT_APP_API;
const moment = require("moment");

function StudentsList() {
    let unmounted = useRef(false);
    const [state, setState] = useState({
        list: {},
        listTotal: 0,
        currentPage: 1,
        pp: 10,
        loader: true,
        searchValue: ''
    })

    useEffect(() => {
        document.body.className = 'page-student view-list';
        getList().then(res => {
            let {code, payload, message} = res
            if (code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: payload.list,
                            listTotal: payload.count
                        }
                    })
                }
            } else {
                if (!unmounted.current) {
                    return errorMessage(message)
                }
            }
        });
        return () => {
            document.body.className = '';
            unmounted.current = true
        }
    }, []);

    const getList = async (start = 0, end = 10, search = '') => {
        const queryParams = {
            _start: start,
            _end: end,
            search,
        };

        let res = await axios.get(`${apiUrl}/dashboard/students/list/`, {
            params: queryParams,
            headers: authHeader()
        });

        let {data} = res;

        if (data.error === null) {
            let {count, rows} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            };
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: data.error.code,
                message: data.error.message
            };
        }
    }

    const onChange = page => {
        let {searchValue, pp} = state;
        let start = (page - 1) * pp,
            end = pp * page;

        setState(prevState => {
            return {
                ...prevState,
                currentPage: page,
                loader: true,
            }
        })

        getList(start, end, searchValue).then(res => {
            if (res.code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: res.payload.list,
                            listTotal: res.payload.count
                        }
                    })
                }
            }
        })
    }

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Parent Name',
            dataIndex: 'parentFullName',
            key: 'parentFullName',
        },
        {
            title: 'Created Date',
            dataIndex: 'createddate',
            key: 'createddate',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 102,
            render: (status) => {
                let color = status === true ? 'green' : 'red'
                let tag = status === true ? 'Active' : 'Passive'
                return (
                    <Tag color={color}>
                        {tag.toUpperCase()}
                    </Tag>
                )
            },
        },
        {
            title: 'Purchased',
            key: 'studentPurchase',
            width: 102,
            render: (item) => {
                let color = item.studentPurchase !== null ? 'green' : 'orange'
                let tag = item.studentPurchase !== null ? 'Purchased' : 'Not Purchased'
                return (
                    <Tag color={color}>
                        {tag.toUpperCase()}
                    </Tag>
                )
            },
        },
        {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            render: (verified) => {
                let color = verified === true ? 'green' : 'red'
                let tag = verified === true ? 'Verified' : 'Not Verified'
                return (
                    <Tag color={color}>
                        {tag.toUpperCase()}
                    </Tag>
                )
            },
        },
        {
            title: 'Action',
            key: 'action',
            className: 'action-wrap',
            width: 100,
            render: (item) => (
                <div className="action-box custom-button">
                    <Link className="ant-btn ant-btn-gray" to={`/student/edit/${item.action}`}>
                        Edit
                    </Link>
                </div>
            ),
        },
    ];

    let listDataSource = []

    Object.keys(state.list).map((key) => {
        let {
            id,
            firstName,
            lastName,
            email,
            parentFullName,
            createdAt,
            studentPurchase,
            isActive,
            isVerified
        } = state.list[key]

        listDataSource[key] = {
            key: id,
            firstName,
            lastName,
            email,
            parentFullName,
            studentPurchase,
            // createddate: moment(createdAt, "YYYY-MM-DD").format("ddd, MMM D, h:mm"),
            createddate: moment(createdAt, "YYYY-MM-DDTh:mm").format("ddd, MMM D, H:mm"),
            action: id,
            status: isActive,
            verified: isVerified
        }
        return listDataSource;
    })

    const generateCsv = async () => {
        const res = await axios.get(`${apiUrl}/dashboard/students/export`, {
            headers: authHeader(),
            responseType: 'blob'
        })

        const {data} = res
        if (data) {
            fileDownload(data, 'Students-list.csv')
        }
    }

    const onSearch = value => {
        let {pp} = state;

        setState(prevState => {
            return {
                ...prevState,
                searchValue: value,
                loader: true,
            }
        })
        getList(0, pp, value).then(res => {
            if (res.code === 200) {
                if (!unmounted.current) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            list: res.payload.list,
                            listTotal: res.payload.count,
                            currentPage: 1
                        }
                    })
                }
            }
        })
    }

    let {loader, pp, currentPage, listTotal} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }
            <Breadcrumb>
                <Breadcrumb.Item>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12">
                        <path
                            d="M16,11.5a.5.5,0,0,1-1,0V4.922L9.484,7.688A3.284,3.284,0,0,1,8,8a3.284,3.284,0,0,1-1.484-.312L.609,4.734Q0,4.422,0,3.992t.609-.726L6.516.312A3.284,3.284,0,0,1,8,0,3.284,3.284,0,0,1,9.484.312l5.906,2.953a.95.95,0,0,1,.594.656A.091.091,0,0,1,16,3.985V11.5ZM9.984,8.688,13,7.172v2.2q0,.672-1.461,1.148A11.5,11.5,0,0,1,8,11a11.5,11.5,0,0,1-3.539-.476Q3,10.047,3,9.375v-2.2L6.016,8.688A4.692,4.692,0,0,0,8,9a4.692,4.692,0,0,0,1.984-.312Z"
                            fill="#11289c"/>
                    </svg>
                    <span>Students</span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className="tool-box">
                <div className="filter-box">
                    <div className="field-box">
                        <Search
                            autoComplete={new Date().valueOf()}
                            placeholder="Search by Name"
                            onSearch={onSearch}/>
                    </div>
                </div>
                <div className="action-box">
                    <Button
                        type="primary"
                        className="button"
                        onClick={generateCsv}
                    >
                        Generate CSV
                    </Button>
                </div>
            </div>

            <Table pagination={false} columns={columns} dataSource={listDataSource}/>

            <Pagination
                simple
                defaultPageSize={pp}
                className="ant-table-pagination ant-table-pagination-right"
                current={currentPage}
                onChange={onChange}
                total={listTotal}
            />
        </>
    );
}

export default StudentsList;
