/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import axios from "axios";
import {
    authHeader,
    errorMessage, successMessage,
} from "../../../../_helpers";
import Spin from 'antd/lib/spin';
import Breadcrumb from "antd/lib/breadcrumb";
import Form from "antd/lib/form";
import Button from "antd/lib/button";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import Student from "./student";
import Empty from "antd/lib/empty";
import Popconfirm from 'antd/lib/popconfirm';
import {QuestionCircleOutlined} from '@ant-design/icons';
import InputNumber from "antd/lib/input-number";

const apiUrl = process.env.REACT_APP_API;

function ResultEdit(props) {
    let {resultId} = props.match.params
    const [form] = Form.useForm();
    const [state, setState] = useState({
        empty: true,
        studentId: null,
        studentInfo: {
            firstName: null,
            lastName: null
        },
        visibleDeletePop: false,
        confirmLoading: false,
        loader: false,
        GradesList: []
    })

    const validateMessages = {
        required: '${label} is required!',
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        document.body.className = 'page-results view-edit';
        getResultById(resultId).then(res => {
            if (res.code === 200) {
                let data = res.payload
                let {
                    award,
                    gradeId,
                    rank,
                    rankForFront,
                    state: province,
                    studentFName,
                    studentId,
                    studentLName
                } = data
                form.setFieldsValue({
                    award,
                    gradeId,
                    rank,
                    rankForFront,
                    state: province,
                });
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                        empty: false,
                        studentId,
                        studentInfo: {
                            firstName: studentFName,
                            lastName: studentLName
                        }
                    }
                })
            } else {
                setState(prevState => {
                    return {
                        ...prevState,
                        empty: true,
                        loader: false
                    }
                })
                errorMessage(res.message).then(res => {
                    if (res) {
                        window.location.href = "/result/list";
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }
        });
        return () => {
            document.body.className = '';
        }
    }, [form, resultId]);

    useEffect(() => {
        getGradesList().then(res => {
            if (res) {
                setState(prevState => {
                    return {
                        ...prevState,
                        GradesList: res
                    }
                })
            }
        })

        return () => {
        }
    }, []);

    const getResultById = async (id) => {
        let res = await axios.get(`${apiUrl}/dashboard/results/${id}`, {
            headers: authHeader()
        });
        let {data} = res;

        let payload
        if (data.error === null) {
            payload = {
                code: 200,
                payload: data.payload
            }
        } else {
            payload = {
                code: 400,
                message: data.error.message
            }
        }
        return payload;
    }

    const getGradesList = async () => {
        let res = await axios.get(`${apiUrl}/student/grades`);
        let {data} = res;
        if (data.error === null) {
            return data.payload
        }
    }

    const Update = async values => {
        let {studentId} = state

        if (!studentId) {
            return errorMessage("Please select a student")
        }

        let {gradeId, rank, award, state: province, rankForFront} = values


        let postData = {
            studentId,
            gradeId,
            rank,
            award,
            rankForFront,
            state: province
        }

        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })

        let res = await axios.put(`${apiUrl}/dashboard/results/${resultId}`, postData, {
            headers: authHeader()
        });

        let {data} = res;

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })
            return successMessage('Results successfully updated')
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })
            return errorMessage(data.error.message)
        }
    }

    const handleStudentIdChange = props => {
        setState(prevState => {
            return {
                ...prevState,
                studentId: props
            }
        })
    }

    const deleteResult = async () => {
        setState(prevState => {
            return {
                ...prevState,
                confirmLoading: true
            }
        })

        let res = await axios.delete(`${apiUrl}/dashboard/results/${resultId}`, {
            headers: authHeader()
        });
        let {data} = res;

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    confirmLoading: false,
                    visibleDeletePop: false
                }
            })
            successMessage(data.payload.message).then(res => {
                if (res) {
                    window.location = `/result/list`
                }
            })
        } else {
            return errorMessage(data.error.message)
        }
    };

    let {loader, empty, GradesList, studentId, studentInfo, visibleDeletePop, confirmLoading} = state

    return (
        <>
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }
            {
                empty ? (
                    <>
                        <Empty/>
                    </>
                ) : (
                    <>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/result/list">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10.629"
                                     viewBox="0 0 15 10.629">
                                    <g transform="translate(-2.842 -7.258)">
                                        <path
                                            d="M24.174,7.573a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0V8.7c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314V7.572Z"
                                            transform="translate(-6.332)" fill="#11289c"/>
                                        <path
                                            d="M24.174,12.938a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0v1.13c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314Z"
                                            transform="translate(-6.332 -2.711)" fill="#11289c"/>
                                        <path
                                            d="M7.268,7.573a.314.314,0,0,0-.314-.314l-.022,0H3.177l-.022,0a.314.314,0,0,0-.314.314v3.8a.314.314,0,0,0,.314.314h3.8a.314.314,0,0,0,.314-.314v-3.8Z"
                                            transform="translate(0 -0.001)" fill="#11289c"/>
                                        <path
                                            d="M24.174,20.107a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0v1.13c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314V20.106Z"
                                            transform="translate(-6.332 -6.333)" fill="#11289c"/>
                                        <path
                                            d="M24.174,25.471a.314.314,0,0,0-.314-.314H15.687v0a.314.314,0,0,0-.312.312h0V26.6c0,.005,0,.01,0,.015a.314.314,0,0,0,.314.314H23.86a.314.314,0,0,0,.314-.314Z"
                                            transform="translate(-6.332 -9.043)" fill="#11289c"/>
                                        <path
                                            d="M7.268,20.106a.314.314,0,0,0-.314-.314l-.022,0H3.177l-.022,0a.314.314,0,0,0-.314.314v3.8a.314.314,0,0,0,.314.314h3.8a.314.314,0,0,0,.314-.314Z"
                                            transform="translate(0 -6.333)" fill="#11289c"/>
                                    </g>
                                </svg>
                                <span>Results</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Edit</Breadcrumb.Item>
                        </Breadcrumb>

                        <Form
                            form={form}
                            name="featured-center-create"
                            onFinish={Update}
                            onFinishFailed={onFinishFailed}
                            validateMessages={validateMessages}
                        >
                            <div className="form-box">
                                <div className="field-row half">
                                    <Form.Item
                                        name="gradeId"
                                        label="Grade"
                                        rules={[{required: true}]}>
                                        <Select
                                            virtual={false}
                                            autoComplete={new Date().valueOf()}
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Select grade"
                                            allowClear
                                            showArrow
                                        >
                                            {Object.keys(GradesList).map((key) => {
                                                let listItem = GradesList[key]
                                                return (
                                                    <Select.Option key={key}
                                                                   value={listItem.id}>{listItem.label}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="state"
                                        label="State">
                                        <Input placeholder="Write State"/>
                                    </Form.Item>

                                </div>

                                <div className="field-row half">
                                    <Student studentId={studentId} studentInfo={studentInfo}
                                             handleStudentIdChange={handleStudentIdChange}/>

                                    <Form.Item
                                        name="rank"
                                        label="Rank"
                                        rules={[{required: true}]}>
                                        <InputNumber
                                            placeholder="Write Rank"
                                        />
                                    </Form.Item>
                                </div>

                                <div className="field-row half">
                                    <Form.Item
                                        name="award"
                                        label="Award">
                                        <Input placeholder="Write Award"/>
                                    </Form.Item>

                                    <Form.Item
                                        name="rankForFront"
                                        label="Rank For Front"
                                        rules={[{required: true}]}>
                                        <Input placeholder="Write Rank For Front"/>

                                    </Form.Item>
                                </div>

                                <div className="field-row half">
                                    <Form.Item shouldUpdate={true}>
                                        {() => (
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="button"
                                            >
                                                Update
                                            </Button>
                                        )}
                                    </Form.Item>

                                    <Popconfirm
                                        title="Are you sure?"
                                        visible={visibleDeletePop}
                                        onConfirm={deleteResult}
                                        okButtonProps={{loading: confirmLoading}}
                                        onCancel={() => {
                                            setState(prevState => {
                                                return {
                                                    ...prevState,
                                                    visibleDeletePop: false
                                                }
                                            })
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                        icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                                    >
                                        <Button type="primary" danger onClick={() => {
                                            setState(prevState => {
                                                return {
                                                    ...prevState,
                                                    visibleDeletePop: true
                                                }
                                            })
                                        }}>
                                            Delete
                                        </Button>
                                    </Popconfirm>
                                </div>

                            </div>
                        </Form>
                    </>
                )
            }
        </>
    );
}

export default ResultEdit;
