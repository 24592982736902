/* eslint-disable no-template-curly-in-string */
import React, {useState} from 'react';
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import StudentList from "./list";
import {errorMessage} from "../../../../../_helpers";

function Student(props) {
    const [state, setState] = useState({
        studentRow: {},
        studentId: props.studentId,
        showStudentModal: false,
        studentTempId: null,
        updateComponent: null,
        student: {
            firstName: props.studentInfo.firstName,
            lastName: props.studentInfo.lastName,
        }
    })

    const handleStudentSelect = values => {
        console.log(values);
        if (values) {
            setState(prevState => {
                return {
                    ...prevState,
                    studentRow: values
                }
            })
        }
    }

    const submitStudent = async e => {

        let {studentRow} = state

        let {firstName, lastName, key} = studentRow

        if (!key) {
            return errorMessage('Please select at last one student')
        }
        setState(prevState => {
            return {
                ...prevState,
                studentId: key,
                student: {
                    firstName: firstName,
                    lastName: lastName,
                },
                showStudentModal: false
            }
        })
        props.handleStudentIdChange(key);
    }

    let {student, showStudentModal, studentId, updateComponent} = state

    return (
        <>
            <>
                <div className="ant-row ant-form-item" style={{rowGap: '0'}}>
                    <div className="ant-col ant-form-item-label">
                        <label htmlFor="featured-center-create_rank" className="ant-form-item-required"
                               title="Student">Student</label></div>
                    <div className="ant-col ant-form-item-control">
                        <div className="ant-form-item-control-input">
                            <div className="ant-form-item-control-input-content">
                                {studentId ? (
                                    <div className="student-box">
                                        <div className="user-info">
                                            <div className="student-name">
                                                {student.firstName + ' ' + student.lastName}
                                            </div>
                                        </div>

                                        <div className="action">
                                            <Button type="gray" onClick={() => {
                                                setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        updateComponent: prevState.updateComponent !== new Date().getTime() ? new Date().getTime() : prevState.updateComponent,
                                                        showStudentModal: true
                                                    }
                                                })
                                            }}>
                                                Edit
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="student-box customer-empty">
                                        <div className="button assign" onClick={() => {
                                            setState(prevState => {
                                                return {
                                                    ...prevState,
                                                    updateComponent: prevState.updateComponent !== new Date().getTime() ? new Date().getTime() : prevState.updateComponent,
                                                    showStudentModal: true
                                                }
                                            })
                                        }}>
                                            Select Student
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <Modal
                centered
                closable={false}
                visible={showStudentModal}
                maskClosable={false}
                width={1000}
                footer={[
                    <Button key="close" onClick={() => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                updateComponent: prevState.updateComponent !== new Date().getTime() ? new Date().getTime() : prevState.updateComponent,
                                showStudentModal: false
                            }
                        })
                    }}>
                        Close
                    </Button>,
                    <Button key="submit" type="primary" onClick={submitStudent}>
                        Submit
                    </Button>,
                ]}
            >
                <StudentList updateComponent={updateComponent} handleStudentSelect={handleStudentSelect}
                             studentId={studentId}/>
            </Modal>
        </>
    );
}

export default Student;
